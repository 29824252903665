<template>
	<div class="trainerachieve">

		<div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
			<div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
				<el-select v-model="trainer_mgu_id" size="small" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading" @change="init">
					<el-option v-for="item in trainer_opts" :key="item.trainer_mgu_id" :label="item.txt" :value="item.trainer_mgu_id"></el-option>
				</el-select>
				<el-button slot="append" class="search-btn" type="success" size="small" style="margin-left:10px;" @click="init">查询</el-button>
			</div>
			<div class="filters">
				<el-date-picker v-model="dr_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="init"></el-date-picker>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="dr_cate" @change="init">
					<el-radio-button label="day">天</el-radio-button>
					<el-radio-button label="month">月</el-radio-button>
					<el-radio-button label="year">年</el-radio-button>
				</el-radio-group>
			</div>
		</div>

        <div class="fc_gray fs_small">注：搜索教练后，查询相应的业绩统计。</div>
		<div class="nav-list f-r-s" style="height:225px;margin-bottom:50px;overflow-y:hidden;overflow: auto;white-space: nowrap">
			<div class="nav-box f-c-c bc_golden" style="min-width:240px;">
				<div class="num">{{vip_count}}/{{vip_total}}</div>
				<div class="name">新开/总会员</div>
			</div>
			<div class="nav-box f-c-c bc_red" style="min-width:240px;">
				<div class="num">{{plan_count}}/{{plan_total}}</div>
				<div class="name">训练规划</div>
			</div>
			<div class="nav-box f-c-c bc_orange" style="min-width:240px;">
				<div class="num">{{contract_count}}/{{contract_total}}</div>
				<div class="name">合同</div>
			</div>
			<div class="nav-box f-c-c bc_cyan" style="min-width:240px;">
				<div class="num">{{effect_count}}/{{effect_total}}</div>
				<div class="name">训练效果</div>
			</div>
			<div class="nav-box f-c-c bc_blue" style="min-width:240px;">
				<div class="num">{{planpage_total}}</div>
				<div class="name">规划模板</div>
			</div>
		</div>

		<div style="margin-bottom:50px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增合同：{{contract_count}}<span style="margin-left: 25px;">合同总数：{{contract_total}}</span></div>
					<el-radio-group size="mini" v-model="contract_ctype" @change="drawContract">
						<el-radio-button label="bar"><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line"><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartContract" ref="echartContract" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增训练规划：{{plan_count}}<span style="margin-left: 25px;">训练规划总数：{{plan_total}}</span></div>
					<el-radio-group size="mini" v-model="plan_ctype" @change="drawPlan">
						<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartPlan" ref="echartPlan" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增训练效果：{{effect_count}}<span style="margin-left: 25px;">训练效果总数：{{effect_total}}</span></div>
					<el-radio-group size="mini" v-model="effect_ctype" @change="drawEffect">
						<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartEffect" ref="echartEffect" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div class="txtrow">新增会员：{{vip_count}}<span style="margin-left: 25px;">会员总数：{{vip_total}}</span></div>
					<el-radio-group size="mini" v-model="vip_ctype" @change="drawVip">
						<el-radio-button label="bar"><i class="el-icon-s-data"></i></el-radio-button>
						<el-radio-button label="line"><i class="el-icon-s-marketing"></i></el-radio-button>
					</el-radio-group>
				</div>
				<div id="echartVip" ref="echartVip" style="width:400px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
			<div style="margin:0 50px 50px 0;">
				<div style="display: flex;align-items: center;justify-content: space-between;">
					<div>
						<div class="txtrow">问卷：{{surveyfaq_count}}</div>
						<el-radio-group size="mini" v-model="surveyfaq_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">体适能：{{surveypft_count}}</div>
						<el-radio-group size="mini" v-model="surveypft_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">静态：{{surveypfts_count}}</div>
						<el-radio-group size="mini" v-model="surveypfts_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">动态：{{surveypftd_count}}</div>
						<el-radio-group size="mini" v-model="surveypftd_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">FMS：{{surveyfms_count}}</div>
						<el-radio-group size="mini" v-model="surveyfms_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
					<div style="width: 10px;"></div>
					<div>
						<div class="txtrow">性格测试：{{surveyfpa_count}}</div>
						<el-radio-group size="mini" v-model="surveyfpa_ctype" @change="drawSurvey">
							<el-radio-button label="bar" border><i class="el-icon-s-data"></i></el-radio-button>
							<el-radio-button label="line" border><i class="el-icon-s-marketing"></i></el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div id="echartSurvey" ref="echartSurvey" style="width:850px;height:300px;margin-top:10px;border:1px solid #E4E4E4;border-radius:5px;"></div>
			</div>
		</div>

	</div>
</template>
<script>
	import * as echarts from 'echarts';
	import {
		mapState
	} from 'vuex'
	import {
		formatPhoneno
	} from "@/utils/formatData"
	const defaultTrainer = {
		trainer_mgu_id: 0,
		txt: '未选择教练'
	}
	export default {
		name: 'user_trainerachieve',
		data() {
			return {
				gym_id: 0,
				trainer_opts: [{
					...defaultTrainer
				}],
				trainer_mgu_id: 0,
				drOpts: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				dr_range: '',
				dr_cate: 'month',
				
				loading: false,
				
				planpage_total: 0,

				vip_ctype: 'bar',
				vip_count: 0,
				vip_total: 0,
				echartVip: '',
				contract_ctype: 'bar',
				contract_count: 0,
				contract_total: 0,
				echartContract: '',
				plan_ctype: 'bar',
				plan_count: 0,
				plan_total: 0,
				echartPlan: '',
				effect_ctype: 'bar',
				effect_count: 0,
				effect_total: 0,
				echartEffect: '',
				surveyfaq_ctype: 'line',
				surveyfaq_count: 0,
				surveypft_ctype: 'line',
				surveypft_count: 0,
				surveypfts_ctype: 'line',
				surveypfts_count: 0,
				surveypftd_ctype: 'line',
				surveypftd_count: 0,
				surveyfms_ctype: 'line',
				surveyfms_count: 0,
				surveyfpa_ctype: 'bar',
				surveyfpa_count: 0,
				echartSurvey: ''
			}
		},
		computed: {
			...mapState(['user'])
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.remoteMethod()
			this.init()
		},
		methods: {
			remoteMethod(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/User/remoteMguTrainers", {
						gym_id: this.gym_id,
						cdit: query
					}).then(res => {
						if (res.rst == 0) {
							this.trainer_opts = [{
								...defaultTrainer
							}]
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.trainer_opts.push({
										trainer_mgu_id: item.id,
										txt: item.trainer.name + '（' + item.trainer.nickname + ' ' + formatPhoneno(item.trainer.phoneno) + '）'
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			init() {
                if (!this.trainer_mgu_id) {
                    this.$message({
                        message: '注意，搜索教练后，查询相应的业绩统计。',
                        type: 'warning'
                    })
                    return
                }
				let _data = {
					gym_id: this.gym_id,
					trainer_mgu_id: this.trainer_mgu_id,
					dr_cate: this.dr_cate
				}
				if (this.dr_range && 2 == this.dr_range.length) {
					_data['from_dt'] = Math.floor(this.dr_range[0] / 1000)
					_data['to_dt'] = Math.floor(this.dr_range[1] / 1000)
				}
				this.$post('/admin/User/getTrainerachievePageInfo', _data).then(res => {
					if (res.rst == 0) {
						res = res.data
						if (!this.dr_range || 2 != this.dr_range.length) {
							this.dr_range = [1000 * res.from_dt, 1000 * res.to_dt]
						}
						//this.$forceUpdate()
						this.planpage_total = res.planpage_total ? res.planpage_total : 0;
						this.vip_count = res.vip_count ? res.vip_count : 0;
						this.vip_total = res.vip_total ? res.vip_total : 0;
						this.vip_xs = []
						this.vip_ys = []
						Object.keys(res.vip_vs).forEach((item, ix) => {
							this.vip_xs.push(item)
							this.vip_ys.push(res.vip_vs[item])
						})
						this.drawVip()
						this.contract_count = res.contract_count ? res.contract_count : 0;
						this.contract_total = res.contract_total ? res.contract_total : 0;
						this.contract_xs = []
						this.contract_ys = []
						Object.keys(res.contract_vs).forEach((item, ix) => {
							this.contract_xs.push(item)
							this.contract_ys.push(res.contract_vs[item])
						})
						this.drawContract()
						this.plan_count = res.plan_count ? res.plan_count : 0;
						this.plan_total = res.plan_total ? res.plan_total : 0;
						this.plan_xs = []
						this.plan_ys = []
						Object.keys(res.plan_vs).forEach((item, ix) => {
							this.plan_xs.push(item)
							this.plan_ys.push(res.plan_vs[item])
						})
						this.drawPlan()
						this.effect_count = res.effect_count ? res.effect_count : 0;
						this.effect_total = res.effect_total ? res.effect_total : 0;
						this.effect_xs = []
						this.effect_ys = []
						Object.keys(res.effect_vs).forEach((item, ix) => {
							this.effect_xs.push(item)
							this.effect_ys.push(res.effect_vs[item])
						})
						this.drawEffect()
						this.surveyfaq_count = res.surveyfaq_count ? res.surveyfaq_count : 0;
						this.surveyfaq_xs = []
						this.surveyfaq_ys = []
						Object.keys(res.surveyfaq_vs).forEach((item, ix) => {
							this.surveyfaq_xs.push(item)
							this.surveyfaq_ys.push(res.surveyfaq_vs[item])
						})
						this.surveypft_count = res.surveypft_count ? res.surveypft_count : 0;
						this.surveypft_xs = []
						this.surveypft_ys = []
						Object.keys(res.surveypft_vs).forEach((item, ix) => {
							this.surveypft_xs.push(item)
							this.surveypft_ys.push(res.surveypft_vs[item])
						})
						this.surveypfts_count = res.surveypfts_count ? res.surveypfts_count : 0;
						this.surveypfts_xs = []
						this.surveypfts_ys = []
						Object.keys(res.surveypfts_vs).forEach((item, ix) => {
							this.surveypfts_xs.push(item)
							this.surveypfts_ys.push(res.surveypfts_vs[item])
						})
						this.surveypftd_count = res.surveypftd_count ? res.surveypftd_count : 0;
						this.surveypftd_xs = []
						this.surveypftd_ys = []
						Object.keys(res.surveypftd_vs).forEach((item, ix) => {
							this.surveypftd_xs.push(item)
							this.surveypftd_ys.push(res.surveypftd_vs[item])
						})
						this.surveyfms_count = res.surveyfms_count ? res.surveyfms_count : 0;
						this.surveyfms_xs = []
						this.surveyfms_ys = []
						Object.keys(res.surveyfms_vs).forEach((item, ix) => {
							this.surveyfms_xs.push(item)
							this.surveyfms_ys.push(res.surveyfms_vs[item])
						})
						this.surveyfpa_count = res.surveyfpa_count ? res.surveyfpa_count : 0;
						this.surveyfpa_xs = []
						this.surveyfpa_ys = []
						Object.keys(res.surveyfpa_vs).forEach((item, ix) => {
							this.surveyfpa_xs.push(item)
							this.surveyfpa_ys.push(res.surveyfpa_vs[item])
						})
						this.drawSurvey()

						this.system_params = res.system_params ? res.system_params : [];
					}
				})
			},
			drawVip() {
				this.echartVip = echarts.init(document.getElementById('echartVip'))
				this.echartVip.showLoading();
				this.echartVip.setOption({
					xAxis: {
						type: 'category',
						data: this.vip_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '会员',
						data: this.vip_ys,
						type: this.vip_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartVip.hideLoading()
			},
			drawContract() {
				this.echartContract = echarts.init(document.getElementById('echartContract'))
				this.echartContract.showLoading();
				this.echartContract.setOption({
					xAxis: {
						type: 'category',
						data: this.contract_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '合同',
						data: this.contract_ys,
						type: this.contract_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartContract.hideLoading()
			},
			drawPlan() {
				this.echartPlan = echarts.init(document.getElementById('echartPlan'))
				this.echartPlan.showLoading();
				this.echartPlan.setOption({
					xAxis: {
						type: 'category',
						data: this.plan_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '训练规划',
						data: this.plan_ys,
						type: this.plan_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartPlan.hideLoading()
			},
			drawEffect() {
				this.echartEffect = echarts.init(document.getElementById('echartEffect'))
				this.echartEffect.showLoading();
				this.echartEffect.setOption({
					xAxis: {
						type: 'category',
						data: this.effect_xs
					},
					yAxis: {
						type: 'value'
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '训练效果',
						data: this.effect_ys,
						type: this.effect_ctype,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: '#F0E0C5'
								},
								{
									offset: 0.2,
									color: '#E0D0B5'
								},
								{
									offset: 1,
									color: '#D0C0A5'
								}
							])
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#D0C0A5'
									},
									{
										offset: 0.8,
										color: '#E0D0B5'
									},
									{
										offset: 1,
										color: '#F0E0C5'
									}
								])
							}
						}
					}]
				})
				this.echartEffect.hideLoading()
			},
			drawSurvey() {
				this.echartSurvey = echarts.init(document.getElementById('echartSurvey'))
				this.echartSurvey.showLoading();
				this.echartSurvey.setOption({
					xAxis: {
						type: 'category',
						data: this.surveyfaq_xs
					},
					yAxis: {
						type: 'value'
					},
					legend: {
						data: ['问卷调查', '体适能测评', '静态测评', '动态测评', 'FMS测评', '性格色彩']
					},
					tooltip: {
						trigger: 'axis'
					},
					series: [{
						name: '问卷调查',
						data: this.surveyfaq_ys,
						type: this.surveyfaq_ctype,
						smooth: true
					}, {
						name: '体适能测评',
						data: this.surveypft_ys,
						type: this.surveypft_ctype,
						smooth: true
					}, {
						name: '静态测评',
						data: this.surveypfts_ys,
						type: this.surveypfts_ctype,
						smooth: true
					}, {
						name: '动态测评',
						data: this.surveypftd_ys,
						type: this.surveypftd_ctype,
						smooth: true
					}, {
						name: 'FMS测评',
						data: this.surveyfms_ys,
						type: this.surveyfms_ctype,
						smooth: true
					}, {
						name: '性格色彩',
						data: this.surveyfpa_ys,
						type: this.surveyfpa_ctype,
						smooth: true
					}]
				})
				this.echartSurvey.hideLoading()
			}
		}
	}
</script>
<style lang="less">
	.trainerachieve {

		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}
		}

		/deep/.el-form-item__label {
			text-align: left;
			float: none;
			word-break: break-word;
		}

		.slogan {
			height: 30px;
			line-height: 30px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
		}

		.home-hr {
			width: 100%;
			height: 1px;
			background: #F0F0F0;
		}

		.title {
			font-weight: 500;
			color: #333333;
			font-size: 20px;
			margin: 30px 0;
		}

		.nav-list {
			.nav-box {
				margin-right: 50px;
				height: 180px;
				border-radius: 20px;
				box-shadow: 3px 3px 7px 0px rgba(102, 102, 102, 0.35);

				.num {
					font-size: 50px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}

				.name {
					font-size: 28px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}
			}

			.nav-box-w {
				width: 400px;
			}

			//num_colors1: ['#C0E0EA', '#FFE5CC', '#C7F5F5', '#FCDBDB', '#FFF8C6', '#CFFEEE', '#E7D4FF'],
			//num_colors2: ['#33B4D9', '#FF8F1F', '#07B9B9', '#FA5151', '#FFC300', '#00B578', '#8A38F5'],
			.bc_cyan {
				background: linear-gradient(138deg, #23a4c9, #33B4D9);
				color: white;
			}

			.bc_purple {
				background: linear-gradient(138deg, #7A28E5, #8A38F5);
				color: white;
			}

			.box0 {
				background: linear-gradient(138deg, #F0E0C5, #d0c0a5);
			}

			.box1 {
				background: linear-gradient(138deg, #A5F46E, #6FC334);
			}

			.box2 {
				background: linear-gradient(138deg, #FFDBA6, #ED991F);
			}

			.box3 {
				background: linear-gradient(138deg, #C0E0EA, #33B4D9);
			}

			.box4 {
				background: linear-gradient(138deg, #FF9F7E, #EB5753);
			}

			.box5 {
				background: linear-gradient(138deg, #C7F5F5, #07B9B9);
			}

			.box6 {
				background: linear-gradient(138deg, #E191FF, #9959F8);
			}

			.box7 {
				background: linear-gradient(138deg, #9AC7FF, #569EF8);
			}

		}
	}
</style>