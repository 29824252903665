<template>
    <div class="bm">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-select v-model="mgu_id" size="small" filterable remote clearable placeholder="请输入会员姓名或手机号" :remote-method="remoteMethodVip" :loading="loading" @change="onSearchClick">
                    <el-option v-for="item in vip_opts" :key="item.mgu_id" :label="item.txt" :value="item.mgu_id"></el-option>
                </el-select>
                <el-select v-model="trainer_mgu_id" size="small" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethodTrainer" :loading="loading" style="margin-left:10px;" @change="onSearchClick">
                    <el-option v-for="item in trainer_opts" :key="item.trainer_mgu_id" :label="item.txt" :value="item.trainer_mgu_id"></el-option>
                </el-select>
                <el-button slot="append" class="search-btn" type="success" size="small" style="margin-left:10px;" @click="onSearchClick">查询</el-button>
            </div>
            <div class="filters">
                <el-date-picker v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
            </div>
        </div>

        <div class="fc_gray fs_small">注：搜索选择会员、教练后，查询相应的健康筛查。</div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="创建" width="250">
                <template slot-scope="scope">
                    <div class="txtrow">时间：{{ scope.row.create_dt | formatDt }}</div>
                    <div class="txtrow">会员：{{scope.row.vip.name}}<span v-if="scope.row.vip.phoneno">（{{scope.row.vip.phoneno}}）</span></div>
                    <div class="txtrow">教练：{{scope.row.trainer.name}}<span v-if="scope.row.trainer.nickname|| scope.row.trainer.phoneno">（{{scope.row.trainer.nickname}} {{scope.row.trainer.phoneno | formatPhoneno}}）</span></div>
                </template>
            </el-table-column>
            <el-table-column label="基本信息" width="280">
                <template slot-scope="scope">
                    <div><span style="display:inline-block;width:100px;">性别：{{1==scope.row.sex?'男':'女'}}</span><span style="display:inline-block;width:100px;">年龄：{{scope.row.age}}岁</span></div>
                    <div><span style="display:inline-block;width:100px;">身高：{{scope.row.height}}cm</span><span style="display:inline-block;width:100px;">体重：{{scope.row.weight}}kg</span></div>
                    <div><span style="display:inline-block;width:100px;">BMI：{{scope.row.bmi}}</span><span style="display:inline-block;width:100px;">体脂率：{{scope.row.bfr}}%</span></div>
                </template>
            </el-table-column>
            <el-table-column label="健康指标" width="380">
                <template slot-scope="scope">
                    <div><span style="display:inline-block;width:100px;">水分：{{scope.row.tbw>0.009?scope.row.tbw:'--'}}kg</span><span style="display:inline-block;width:100px;">骨骼肌：{{scope.row.bon>0.009?scope.row.bon:'--'}}kg</span><span style="display:inline-block;width:100px;">肌肉量：{{scope.row.mus>0.009?scope.row.mus:'--'}}kg</span></div>
                    <div><span style="display:inline-block;width:100px;">体型：<span :class="scope.row.body_color">{{scope.row.body_shape?scope.row.body_shape:'--'}}</span></span><span style="display:inline-block;width:100px;">脂肪量：{{scope.row.fat>0.009?scope.row.fat:'--'}}kg</span><span style="display:inline-block;width:100px;">臀腰比：{{scope.row.whr>0.009?scope.row.whr:'--'}}</span></div>
                    <div><span style="display:inline-block;width:150px;">血压：{{scope.row.bp_s?scope.row.bp_s:'--'}}/{{scope.row.bp_s?scope.row.bp_d:'--'}}mmHg</span><span style="display:inline-block;width:100px;">心率：{{scope.row.hr?scope.row.hr:'--'}}bpm</span></div>
                </template>
            </el-table-column>
            <el-table-column label="维度测量">
                <template slot-scope="scope">
                    <div><span style="display:inline-block;width:100px;">胸围：{{scope.row.bc>0.009?scope.row.bc:'--'}}cm</span><span style="display:inline-block;width:160px;">腰围：{{scope.row.wc>0.009?scope.row.wc:'--'}}/{{scope.row.wc_2>0.009?scope.row.wc_2:'--'}}/{{scope.row.wc_3>0.009?scope.row.wc_3:'--'}}cm</span><span style="display:inline-block;width:100px;">臀围：{{scope.row.hc>0.009?scope.row.hc:'--'}}cm</span></div>
                    <div><span style="display:inline-block;width:180px;">左上臂围：{{scope.row.lac>0.009?scope.row.lac:'--'}}cm</span><span style="display:inline-block;width:180px;">右上臂围：{{scope.row.rac>0.009?scope.row.rac:'--'}}cm</span></div>
                    <div><span style="display:inline-block;width:180px;">左大腿围：{{scope.row.ltc>0.009?scope.row.ltc:'--'}}cm</span><span style="display:inline-block;width:180px;">右大腿围：{{scope.row.rtc>0.009?scope.row.rtc:'--'}}cm</span></div>
                    <div><span style="display:inline-block;width:180px;">左小腿围：{{scope.row.ltc>0.009?scope.row.llc:'--'}}cm</span><span style="display:inline-block;width:180px;">右小腿围：{{scope.row.rlc>0.009?scope.row.rtc:'--'}}cm</span></div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import {
        formatPhoneno
    } from "@/utils/formatData"
    const defaultVip = {
        mgu_id: 0,
        txt: '未选择会员'
    }
    const defaultTrainer = {
        trainer_mgu_id: 0,
        txt: '未选择教练'
    }
    export default {
        name: "survey_bm",
        data() {
            return {
                remote_mgu_id: 0,

                gym_id: 0,

                vip_opts: [{
                    ...defaultVip
                }],
                trainer_opts: [{
                    ...defaultTrainer
                }],

                drOpts: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date_range: '',

                mgu_id: 0,
                trainer_mgu_id: 0,

                loading: false,
                list: [],
                total: 0,
                page_ix: 1,
                page_size: 10
            };
        },
        computed: {
            ...mapState(["user"]),
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        watch: {
            "$route.query.mgu_id": {
                immediate: true,
                handler() {
                    if ('/survey_bm' == this.$route.path && this.$route.query.mgu_id && this.$route.query.mgu_id != this.remote_mgu_id) {
                        this.remote_mgu_id = this.$route.query.mgu_id
                        this.remoteMethodVip()
                    }
                }
            }
        },
        mounted() {
            this.gym_id = this.user.gym.id
            if (!this.$route.query.mgu_id) {
                this.remoteMethodVip()
            }
            this.remoteMethodTrainer()
        },
        methods: {
            remoteMethodVip(query) {
                setTimeout(() => {
                    let _data = {
                        gym_id: this.gym_id
                    }
                    if (query) {
                        _data['cdit'] = query
                    } else {
                        if (this.$route.query.mgu_id) {
                            _data['mgu_id'] = this.$route.query.mgu_id
                        }
                    }
                    this.loading = true
                    this.$post("/admin/Survey/remoteVips", _data).then(res => {
                        if (res.rst == 0) {
                            this.vip_opts = [{
                                ...defaultVip
                            }]
                            res = res.data
                            if (res && res.length > 0) {
                                res.forEach((item, ix) => {
                                    this.vip_opts.push({
                                        mgu_id: item.id,
                                        txt: item.vip.name + '（' + formatPhoneno(item.vip.phoneno) + '）'
                                    })
                                    if (this.remote_mgu_id && this.remote_mgu_id == item.id) {
                                        this.mgu_id = item.id
                                    }
                                })
                            }
                        }
                    }).finally(() => {
                        this.loading = false
                        this.getList()
                    })
                }, 250)
            },
            remoteMethodTrainer(query) {
                setTimeout(() => {
                    this.loading = true;
                    this.$post("/admin/Survey/remoteTrainers", {
                        gym_id: this.gym_id,
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.trainer_opts = [{
                                ...defaultTrainer
                            }]
                            res = res.data
                            if (res && res.length > 0) {
                                res.forEach((item, ix) => {
                                    this.trainer_opts.push({
                                        trainer_mgu_id: item.id,
                                        txt: item.trainer.name + '（' + item.trainer.nickname + ' ' + formatPhoneno(item.trainer.phoneno) + '）'
                                    })
                                })
                            }
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }, 250)
            },
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    mgu_id: this.mgu_id,
                    trainer_mgu_id: this.trainer_mgu_id,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.date_range) {
                    _data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
                    _data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
                }
                this.$post("/admin/Survey/queryBm", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.list.forEach((item, ix) => {
                                if (item.weight > 0.009 && item.height > 0.009) {
                                    if (1 == item.sex) {
                                        item.weight_std = 0.7 * (item.height - 80)
                                    } else {
                                        item.weight_std = 0.6 * (item.height - 70)
                                    }
                                    const weight_level = Math.floor((item.weight - item.weight_std) / (0.05 * item.weight_std))
                                    if (weight_level >= 1) {
                                        if (weight_level >= 4) {
                                            item.body_shape = '肥胖'
                                            item.body_color = 'fc_red'
                                        } else if (weight_level >= 2) {
                                            item.body_shape = '偏胖'
                                            item.body_color = 'fc_orange'
                                        } else {
                                            item.body_shape = '微胖'
                                            item.body_color = 'fc_blue'
                                        }
                                    } else if (-1 >= weight_level) {
                                        if (-4 >= weight_level) {
                                            item.body_shape = '太瘦'
                                            item.body_color = 'fc_red'
                                        } else if (-2 >= weight_level) {
                                            item.body_shape = '偏瘦'
                                            item.body_color = 'fc_orange'
                                        } else {
                                            item.body_shape = '略瘦'
                                            item.body_color = 'fc_blue'
                                        }
                                    } else {
                                        item.body_shape = '正常'
                                        item.body_color = 'fc_green'
                                    }
                                    item.bmi = Math.round(100 * item.weight / (0.01 * item.height)) / 100
                                    item.bfr = Math.round(100 * (1.2 * item.bmi + 0.23 * item.age - 5.4 - 10.8 * (1 == item.sex ? 1 : 0))) / 100
                                } else {
                                    item.weight_std = 0.0
                                    item.body_shape = '异常'
                                    item.body_color = 'fc_gray'
                                    item.bmi = 0.0
                                    item.bfr = 0.0
                                }
                            })
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            }
        }
    };
</script>
<style lang="less" scoped>
    .bm {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    width: 100px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }
    }
</style>