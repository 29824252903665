<template>
    <div class="acttag">
        <div style="display: flex;align-items: center;justify-content: flex-start;">
            <div>当前场馆：{{user.gym.name+(user.gym.group_sn?(1==user.gym.is_flagship?'（总部：标签右上角数字代表连锁共享场馆数，*-代表全部场馆。）':'（连锁场馆：总部共享标签不可修改。）'):'（单店）')}}</div>
        </div>

        <div class="toolbar f-r-s" style="margin-top:50px;">
            <div class="operation f-r-s">
                <div class="fs_large bold">训练部位</div>
            </div>
            <div v-if="user.gym.group_sn && 1==user.gym.is_flagship" class="filters">
                <el-checkbox v-model="bodypart_isset_share">连锁共享</el-checkbox>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="bodypart_status" @change="getBodypartList">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div v-if="!bodypart_isset_share" class="filters" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-button size="small" type="success" @click="resortBodyparts">保存排序</el-button>
                <el-button size="small" @click="saveBodypart()"><i class="el-icon-plus"></i></el-button>
            </div>
        </div>
        <div :style="{'display':bodypart_isset_share?'':'none'}">
            <div style="display: flex;align-items: center;justify-content: flex-start;">
                <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">
                    <div>
                        <span>一、「点击」选择</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="selectBodyparts('all')">全选</el-button>
                            <el-button type="text" @click="selectBodyparts('none')">不选</el-button>
                            <el-button type="text" @click="selectBodyparts('invert')">反选</el-button>
                        </span>
                        <span>计划设置的身体部位。</span>
                    </div>
                    <div style="margin-left: 25px;">
                        <span>二、</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="goSetShare('bodypart','训练部位')">设置</el-button>
                        </span>
                        <span>选中训练部位的连锁共享。</span>
                    </div>
                </div>
            </div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div v-for="(bodypart, bpx) in bodyparts_list" :key="bpx">
                    <div :class="1==bodypart.status?'bc_golden_bright tag-item':'bc_bright tag-item-rm'" :style="{'box-shadow': bodypart.is_selected?'0 0 3px 3px #FBBC04':''}" @click="selectBodyparts('one',bodypart.id)">
                        {{bodypart.name}}
                        <div class="fs_min" style="position: absolute;top:0;right:2px;">{{bodypart.share_gyms_id?('*'===bodypart.share_gyms_id?'*':bodypart.share_gyms_id.length):'-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div :style="{'display':bodypart_isset_share?'none':''}">
            <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">注：「点击」修改、「双击」{{"1"==bodypart_status?'删除':"0"==bodypart_status?'恢复':'删除/恢复'}}运动模式，「拖动」调整运动模式顺序（别忘记保存哦）。</div>
            <div style="margin-bottom:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div class="fc_gray">系统推荐：</div>
                <div v-for="(bodypart,bpx) in bodyparts_system" :key="bpx">
                    <div class="bodypart-system-item bc_golden" style="margin-left:10px;padding:2px 6px;border-radius:2px;cursor:pointer;" @click="saveBodypart(0,bodypart.name)">{{bodypart.name}}</div>
                </div>
            </div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div id="bodypart-items" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;"></div>
            </div>
        </div>
        <div v-if="user.gym.group_sn && 1!=user.gym.is_flagship" style="margin-top:10px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
            <div class="fc_gray">总部共享训练部位：</div>
            <div v-for="(bodypart,bpx) in bodyparts_list" :key="bpx">
                <div v-if="bodypart.gym_id!=gym_id" class="bodypart-system-item bc_bright" style="margin-left:10px;padding:2px 6px;border-radius:2px;cursor:pointer;">{{bodypart.name}}</div>
            </div>
        </div>

        <div class="toolbar f-r-s">
            <div class="operation f-r-s">
                <div class="fs_large bold">运动模式</div>
            </div>
            <div v-if="user.gym.group_sn && 1==user.gym.is_flagship" class="filters">
                <el-checkbox v-model="mode_isset_share">连锁共享</el-checkbox>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="mode_status" @change="getModeList">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div v-if="!mode_isset_share" class="filters" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-button size="small" type="success" @click="resortModes">保存排序</el-button>
                <el-button size="small" @click="saveMode()"><i class="el-icon-plus"></i></el-button>
            </div>
        </div>
        <div :style="{'display':mode_isset_share?'':'none'}">
            <div style="display: flex;align-items: center;justify-content: flex-start;">
                <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">
                    <div>
                        <span>一、「点击」选择</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="selectModes('all')">全选</el-button>
                            <el-button type="text" @click="selectModes('none')">不选</el-button>
                            <el-button type="text" @click="selectModes('invert')">反选</el-button>
                        </span>
                        <span>计划设置的运动模式。</span>
                    </div>
                    <div style="margin-left: 25px;">
                        <span>二、</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="goSetShare('mode','训练部位')">设置</el-button>
                        </span>
                        <span>选中运动模式的连锁共享。</span>
                    </div>
                </div>
            </div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div v-for="(mode, bpx) in modes_list" :key="bpx">
                    <div :class="1==mode.status?'bc_golden_bright tag-item':'bc_bright tag-item-rm'" :style="{'box-shadow': mode.is_selected?'0 0 3px 3px #FBBC04':''}" @click="selectModes('one',mode.id)">
                        {{mode.name}}
                        <div class="fs_min" style="position: absolute;top:0;right:2px;">{{mode.share_gyms_id?('*'===mode.share_gyms_id?'*':mode.share_gyms_id.length):'-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div :style="{'display':mode_isset_share?'none':''}">
            <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">注：「点击」修改、「双击」{{"1"==mode_status?'删除':"0"==mode_status?'恢复':'删除/恢复'}}运动模式，「拖动」调整运动模式顺序（别忘记保存哦）。</div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div id="mode-items" style="display: flex;align-items: center;justify-content: flex-start;"></div>
            </div>
        </div>
        <div v-if="user.gym.group_sn && 1!=user.gym.is_flagship" style="margin-top:10px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
            <div class="fc_gray">总部共享运动模式：</div>
            <div v-for="(item,ix) in modes_list" :key="ix">
                <div v-if="item.gym_id!=gym_id" class="bodypart-system-item bc_bright" style="margin-left:10px;padding:2px 6px;border-radius:2px;cursor:pointer;">{{item.name}}</div>
            </div>
        </div>

        <div class="toolbar f-r-s">
            <div class="operation f-r-s">
                <div class="fs_large bold">课程类型</div>
            </div>
            <div v-if="user.gym.group_sn && 1==user.gym.is_flagship" class="filters">
                <el-checkbox v-model="course_isset_share">连锁共享</el-checkbox>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="course_status" @change="getCourseList">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div v-if="!course_isset_share" class="filters" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-button size="small" type="success" @click="resortCourses">保存排序</el-button>
                <el-button size="small" @click="saveCourse()"><i class="el-icon-plus"></i></el-button>
            </div>
        </div>
        <div :style="{'display':course_isset_share?'':'none'}">
            <div style="display: flex;align-items: center;justify-content: flex-start;">
                <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">
                    <div>
                        <span>一、「点击」选择</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="selectCourses('all')">全选</el-button>
                            <el-button type="text" @click="selectCourses('none')">不选</el-button>
                            <el-button type="text" @click="selectCourses('invert')">反选</el-button>
                        </span>
                        <span>计划设置的课程类型。</span>
                    </div>
                    <div style="margin-left: 25px;">
                        <span>二、</span>
                        <span style="margin:0 10px;">
                            <el-button type="text" @click="goSetShare('course','训练部位')">设置</el-button>
                        </span>
                        <span>选中运动模式的课程类型。</span>
                    </div>
                </div>
            </div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div v-for="(course, bpx) in courses_list" :key="bpx">
                    <div :class="1==course.status?'bc_golden_bright tag-item':'bc_bright tag-item-rm'" :style="{'box-shadow': course.is_selected?'0 0 3px 3px #FBBC04':''}" @click="selectCourses('one',course.id)">
                        {{course.name}}
                        <div class="fs_min" style="position: absolute;top:0;right:2px;">{{course.share_gyms_id?('*'===course.share_gyms_id?'*':course.share_gyms_id.length):'-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div :style="{'display':course_isset_share?'none':''}">
            <div class="fc_gray fs_small" style="height:50px;display: flex;align-items: center;justify-content: flex-start;">注：「点击」修改、「双击」{{"1"==course_status?'删除':"0"==course_status?'恢复':'删除/恢复'}}运动模式，「拖动」调整运动模式顺序（别忘记保存哦）。</div>
            <div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                <div id="course-items" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;"></div>
            </div>
        </div>
        <div v-if="user.gym.group_sn && 1!=user.gym.is_flagship" style="margin-top:10px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
            <div class="fc_gray">总部共享课程类型：</div>
            <div v-for="(item,ix) in courses_list" :key="ix">
                <div v-if="item.gym_id!=gym_id" class="bodypart-system-item bc_bright" style="margin-left:10px;padding:2px 6px;border-radius:2px;cursor:pointer;">{{item.name}}</div>
            </div>
        </div>

        <el-dialog title="连锁共享" :visible.sync="showModalShare" :before-close="onCloseModalShare">
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div>
                    已与（
                    <span v-if="share_gyms && '*'===share_gyms">所有</span>
                    <span v-else>{{share_gyms_ix.length}}家</span>
                    ）连锁场馆共享
                </div>
                <el-button type="text" @click="shareAll">与所有连锁（包括以后加入的场馆）共享</el-button>
            </div>
            <el-select v-model="share_gyms_ix" filterable remote clearable multiple placeholder="请选择共享场馆" :remote-method="getGroupGyms" style="width: 100%;" @change="changeShareGym">
                <el-option v-for="(item,ix) in group_gyms" :key="ix" :label="item.name" :value="ix"></el-option>
            </el-select>
            <div style="margin-top:25px;display: flex;align-items: center;justify-content: flex-end;">
                <el-button type="primary" @click="onCloseModalShare">取消</el-button>
                <el-button type="primary" @click="onSaveShareGym">保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
    import Sortable from 'sortablejs'
    import {
        mapState
    } from "vuex"
    let timer = null
    export default {
        name: "train_acttag",
        data() {
            return {
                gym_id: 0,

                bodyparts_system: [],
                bodypart_status: 1,
                bodyparts_list: [],
                bodyparts_name: [],
                bodypart_isset_share: false,
                mode_status: 1,
                modes_list: [],
                modes_name: [],
                mode_isset_share: false,
                course_status: 1,
                courses_list: [],
                courses_name: [],
                course_isset_share: false,
                showModalShare: false,

                group_gyms: [],
                share_tag_no: '',
                share_gyms_ix: [],
                share_gyms: [],
                share_gyms_id: []
            }
        },
        computed: {
            ...mapState(["user"])
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getGroupGyms()
            this.getBodypartSystem()
            this.getBodypartList()
            this.getModeList()
            this.getCourseList()

            if (this.user.gym.group_sn && 1 == this.user.gym.is_flagship) {
                this.getGroupGyms()
            }

            document.body.ondrop = function(event) {
                event.preventDefault()
                event.stopPropagation()
            }
        },
        methods: {
            getGroupGyms() {
                this.$post("/admin/Train/getGroupGyms", {
                    gym_id: this.gym_id
                }).then(res => {
                    if (res.rst == 0) {
                        this.group_gyms = res.data
                    }
                })
            },
            getBodypartSystem() {
                this.$post("/admin/Train/getActbodypartsSystem", {

                }).then(res => {
                    if (res.rst == 0) {
                        this.bodyparts_system = res.data
                    }
                })
            },
            getBodypartList() {
                this.bodyparts_list = []
                this.bodyparts_name = []
                let _data = {
                    gym_id: this.gym_id,
                    status: this.bodypart_status
                }
                const that = this
                this.$post("/admin/Train/getActbodyparts", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            that.bodyparts_list = res.data
                            const listBodypart = document.getElementById('bodypart-items')
                            listBodypart.innerHTML = ''
                            res.data.forEach(item => {
                                if (item.gym_id == that.gym_id) {
                                    that.bodyparts_name.push(item.name)
                                    const div = document.createElement('div')
                                    div.textContent = item.name
                                    div.setAttribute('data_id', item.id)
                                    div.setAttribute('class', 1 == item.status ? 'bc_golden_bright tag-item' : 'bc_bright tag-item-rm')
                                    div.addEventListener("click", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“训练部位”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer)
                                        timer = setTimeout(() => {
                                            that.saveBodypart(item.id, item.name)
                                        }, 250)
                                    })
                                    div.addEventListener("dblclick", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“训练部位”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer);
                                        that.handleBodypartStatus(item.id, 1 != item.status ? 1 : 0)
                                    })
                                    if (this.user.gym.group_sn) {
                                        if (1 == this.user.gym.is_flagship) {
                                            const sup = document.createElement('div')
                                            sup.textContent = (item.share_gyms_id ? ('*' === item.share_gyms_id ? '*' : item.share_gyms_id.length) : '-')
                                            sup.setAttribute('class', 'fs_min')
                                            sup.setAttribute('style', 'position: absolute;top:0;right:2px;')
                                            div.appendChild(sup)
                                        } else {
                                            if (that.gym_id != item.gym_id) {
                                                div.setAttribute('class', 'bc_bright tag-item')
                                            }
                                        }
                                    }
                                    listBodypart.appendChild(div)
                                }
                            });
                            new Sortable(listBodypart, {
                                animation: 250
                            });
                        }
                    })
            },
            saveBodypart(bodypart_id = 0, name = '') {
                this.$prompt('请输入训练部位名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: name ? name : '',
                    inputPattern: /^.{2,20}$/,
                    inputErrorMessage: '训练部位名称为2～20个字'
                }).then(({
                    value
                }) => {
                    if (this.bodyparts_name.includes(value)) {
                        this.$message({
                            message: '警告，“' + value + '”已存在。',
                            type: 'warning'
                        })
                        return
                    }
                    let _data = {
                        gym_id: this.gym_id,
                        name: value
                    }
                    if (bodypart_id) {
                        _data['bodypart_id'] = bodypart_id
                    } else {
                        if (this.user.gym.group_sn && 1 == this.user.gym.is_flagship) {
                            _data['share_gyms_id'] = '*'
                        }
                    }
                    this.$post("/admin/Train/saveActbodypart", _data).finally(() => {
                        this.getBodypartList();
                    })
                })
            },
            handleBodypartStatus(bodypart_id, status) {
                if (0 >= bodypart_id || (1 != status && 0 != status)) {
                    return
                }
                this.$confirm("该操作将" + (1 == status ? "恢复" : (0 == status ? "删除" : "修改")) + "课程类型，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Train/saveActbodypart", {
                        bodypart_id,
                        status,
                    }).finally(() => {
                        this.getBodypartList();
                    });
                });
            },
            resortBodyparts() {
                this.$confirm("该操作将更新当前课程类型顺序，且不可撤销，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    const listBodypart = document.getElementById('bodypart-items')
                    let id_sort_pairs = []
                    Array.from(listBodypart.children).forEach((item, ix) => {
                        id_sort_pairs.push({
                            id: item.getAttribute('data_id'),
                            sort: 1 + ix
                        })
                    })
                    this.$post("/admin/Train/resortActbodyparts", {
                        id_sort_pairs: JSON.stringify(id_sort_pairs)
                    }).then(res => {
                        if (res.rst == 0) {
                            this.$message({
                                message: res.msg,
                                type: 0 == res.rst ? 'success' : 'error'
                            });
                        }
                    }).finally((res) => {})
                })
            },
            selectBodyparts(no = '', tag_id = 0) {
                if (!no) {
                    return
                }
                if ('all' === no) {
                    this.bodyparts_list.forEach((item, ix) => {
                        item.is_selected = true
                        this.$forceUpdate()
                    })
                } else if ('none' === no) {
                    this.bodyparts_list.forEach((item, ix) => {
                        item.is_selected = false
                        this.$forceUpdate()
                    })
                } else if ('invert' === no) {
                    this.bodyparts_list.forEach((item, ix) => {
                        item.is_selected = !item.is_selected
                        this.$forceUpdate()
                    })
                } else if ('one' === no) {
                    if (0 >= tag_id) {
                        return
                    }
                    this.bodyparts_list.forEach((item, ix) => {
                        if (tag_id === item.id) {
                            item.is_selected = !item.is_selected
                            this.$forceUpdate()
                        }
                    })
                }
            },
            getModeList() {
                this.modes_list = []
                this.modes_name = []
                let _data = {
                    gym_id: this.gym_id,
                    status: this.mode_status
                }
                const that = this
                this.$post("/admin/Train/getActmodes", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            that.modes_list = res.data
                            const listMode = document.getElementById('mode-items')
                            listMode.innerHTML = ''
                            res.data.forEach(item => {
                                if (item.gym_id == that.gym_id) {
                                    that.modes_name.push(item.name)
                                    const div = document.createElement('div')
                                    div.textContent = item.name
                                    div.setAttribute('data_id', item.id)
                                    div.setAttribute('class', 1 == item.status ? 'bc_golden_bright tag-item' : 'bc_bright tag-item')
                                    div.addEventListener("click", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“运动模式”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer)
                                        timer = setTimeout(() => {
                                            that.saveMode(item.id, item.name)
                                        }, 250)
                                    })
                                    div.addEventListener("dblclick", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“运动模式”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer);
                                        that.handleModeStatus(item.id, 1 != item.status ? 1 : 0)
                                    })
                                    if (this.user.gym.group_sn) {
                                        if (1 == this.user.gym.is_flagship) {
                                            const sup = document.createElement('div')
                                            sup.textContent = (item.share_gyms_id ? ('*' === item.share_gyms_id ? '*' : item.share_gyms_id.length) : '-')
                                            sup.setAttribute('class', 'fs_min')
                                            sup.setAttribute('style', 'position: absolute;top:0;right:2px;')
                                            div.appendChild(sup)
                                        } else {
                                            if (that.gym_id != item.gym_id) {
                                                div.setAttribute('class', 'bc_bright tag-item')
                                            }
                                        }
                                    }
                                    listMode.appendChild(div)
                                }
                            })
                            new Sortable(listMode, {
                                animation: 250
                            })
                        }
                    })
            },
            saveMode(mode_id = 0, name = '') {
                this.$prompt('请输入动作模式名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: mode_id ? name : '',
                    inputPattern: /^.{2,20}$/,
                    inputErrorMessage: '动作模式名称为2～20个字'
                }).then(({
                    value
                }) => {
                    if (this.modes_name.includes(value)) {
                        this.$message({
                            message: '警告，“' + value + '”已存在。',
                            type: 'warning'
                        })
                        return
                    }
                    let _data = {
                        gym_id: this.gym_id,
                        name: value
                    }
                    if (mode_id) {
                        _data['mode_id'] = mode_id
                    } else {
                        if (this.user.gym.group_sn && 1 == this.user.gym.is_flagship) {
                            _data['share_gyms_id'] = '*'
                        }
                    }
                    this.$post("/admin/Train/saveActmode", _data).finally(() => {
                        this.getModeList();
                    })
                })
            },
            handleModeStatus(mode_id, status) {
                if (0 >= mode_id || (1 != status && 0 != status)) {
                    return
                }
                this.$confirm("该操作将" + (1 == status ? "恢复" : (0 == status ? "删除" : "修改")) + "动作模式，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Train/saveActmode", {
                        mode_id,
                        status,
                    }).finally(() => {
                        this.getModeList();
                    });
                });
            },
            resortModes() {
                this.$confirm("该操作将更新当前动作模式顺序，且不可撤销，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    const listMode = document.getElementById('mode-items')
                    let id_sort_pairs = []
                    Array.from(listMode.children).forEach((item, ix) => {
                        id_sort_pairs.push({
                            id: item.getAttribute('data_id'),
                            sort: 1 + ix
                        })
                    })
                    this.$post("/admin/Train/resortActmodes", {
                        id_sort_pairs: JSON.stringify(id_sort_pairs)
                    }).then(res => {
                        if (res.rst == 0) {
                            this.$message({
                                message: res.msg,
                                type: 0 == res.rst ? 'success' : 'error'
                            });
                        }
                    }).finally((res) => {})
                })
            },
            selectModes(no = '', tag_id = 0) {
                if (!no) {
                    return
                }
                if ('all' === no) {
                    this.modes_list.forEach((item, ix) => {
                        item.is_selected = true
                        this.$forceUpdate()
                    })
                } else if ('none' === no) {
                    this.modes_list.forEach((item, ix) => {
                        item.is_selected = false
                        this.$forceUpdate()
                    })
                } else if ('invert' === no) {
                    this.modes_list.forEach((item, ix) => {
                        item.is_selected = !item.is_selected
                        this.$forceUpdate()
                    })
                } else if ('one' === no) {
                    if (0 >= tag_id) {
                        return
                    }
                    this.modes_list.forEach((item, ix) => {
                        if (tag_id === item.id) {
                            item.is_selected = !item.is_selected
                            this.$forceUpdate()
                        }
                    })
                }
            },
            getCourseList() {
                this.courses_list = []
                this.courses_name = []
                let _data = {
                    gym_id: this.gym_id,
                    status: this.course_status
                }
                const that = this
                this.$post("/admin/Train/getActcourses", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            that.courses_list = res.data
                            const listCourse = document.getElementById('course-items')
                            listCourse.innerHTML = ''
                            res.data.forEach(item => {
                                if (item.gym_id == that.gym_id) {
                                    that.courses_name.push(item.name)
                                    const div = document.createElement('div')
                                    div.textContent = item.name
                                    div.setAttribute('data_id', item.id)
                                    div.setAttribute('class', 1 == item.status ? 'bc_golden_bright tag-item' : 'bc_bright tag-item-rm')
                                    div.addEventListener("click", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“课程类型”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer)
                                        timer = setTimeout(() => {
                                            that.saveCourse(item.id, item.name)
                                        }, 250)
                                    })
                                    div.addEventListener("dblclick", function(e) {
                                        if (that.gym_id != item.gym_id) {
                                            that.$alert('总部共享“课程类型”不能修改', '提示', {
                                                confirmButtonText: '知道了'
                                            })
                                            return
                                        }
                                        clearTimeout(timer);
                                        that.handleCourseStatus(item.id, 1 != item.status ? 1 : 0)
                                    })
                                    if (this.user.gym.group_sn) {
                                        if (1 == this.user.gym.is_flagship) {
                                            const sup = document.createElement('div')
                                            sup.textContent = (item.share_gyms_id ? ('*' === item.share_gyms_id ? '*' : item.share_gyms_id.length) : '-')
                                            sup.setAttribute('class', 'fs_min')
                                            sup.setAttribute('style', 'position: absolute;top:0;right:2px;')
                                            div.appendChild(sup)
                                        } else {
                                            if (that.gym_id != item.gym_id) {
                                                div.setAttribute('class', 'bc_bright tag-item')
                                            }
                                        }
                                    }
                                    listCourse.appendChild(div)
                                }
                            });
                            new Sortable(listCourse, {
                                animation: 250
                            });
                        }
                    })
            },
            saveCourse(course_id = 0, name = '') {
                this.$prompt('请输入课程类型名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue: course_id ? name : '',
                    inputPattern: /^.{2,20}$/,
                    inputErrorMessage: '课程类型名称为2～20个字'
                }).then(({
                    value
                }) => {
                    if (this.courses_name.includes(value)) {
                        this.$message({
                            message: '警告，“' + value + '”已存在。',
                            type: 'warning'
                        })
                        return
                    }
                    let _data = {
                        gym_id: this.gym_id,
                        name: value
                    }
                    if (course_id) {
                        _data['course_id'] = course_id
                    } else {
                        if (this.user.gym.group_sn && 1 == this.user.gym.is_flagship) {
                            _data['share_gyms_id'] = '*'
                        }
                    }
                    this.$post("/admin/Train/saveActcourse", _data).finally(() => {
                        this.getCourseList();
                    })
                })
            },
            handleCourseStatus(course_id, status) {
                if (0 >= course_id || (1 != status && 0 != status)) {
                    return
                }
                this.$confirm("该操作将" + (1 == status ? "恢复" : (0 == status ? "删除" : "修改")) + "课程类型，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Train/saveActcourse", {
                        course_id,
                        status,
                    }).finally(() => {
                        this.getCourseList();
                    });
                });
            },
            resortCourses() {
                this.$confirm("该操作将更新当前课程类型顺序，且不可撤销，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    const listCourse = document.getElementById('course-items')
                    let id_sort_pairs = []
                    Array.from(listCourse.children).forEach((item, ix) => {
                        id_sort_pairs.push({
                            id: item.getAttribute('data_id'),
                            sort: 1 + ix
                        })
                    })
                    this.$post("/admin/Train/resortActcourses", {
                        id_sort_pairs: JSON.stringify(id_sort_pairs)
                    }).then(res => {
                        if (res.rst == 0) {
                            this.$message({
                                message: res.msg,
                                type: 0 == res.rst ? 'success' : 'error'
                            });
                        }
                    }).finally((res) => {})
                })
            },
            selectCourses(no = '', tag_id = 0) {
                if (!no) {
                    return
                }
                if ('all' === no) {
                    this.courses_list.forEach((item, ix) => {
                        item.is_selected = true
                        this.$forceUpdate()
                    })
                } else if ('none' === no) {
                    this.courses_list.forEach((item, ix) => {
                        item.is_selected = false
                        this.$forceUpdate()
                    })
                } else if ('invert' === no) {
                    this.courses_list.forEach((item, ix) => {
                        item.is_selected = !item.is_selected
                        this.$forceUpdate()
                    })
                } else if ('one' === no) {
                    if (0 >= tag_id) {
                        return
                    }
                    this.courses_list.forEach((item, ix) => {
                        if (tag_id === item.id) {
                            item.is_selected = !item.is_selected
                            this.$forceUpdate()
                        }
                    })
                }
            },
            goSetShare(tag_no, tag_name) {
                if (!tag_no) {
                    return
                }
                let tags_count = 0
                this[tag_no + 's_list'].forEach((item, ix) => {
                    if (item.is_selected) {
                        tags_count++
                    }
                })
                if (0 >= tags_count) {
                    this.$alert('未选择任何“' + tag_name + '”设置连锁共享！', '提示', {
                        confirmButtonText: '知道了'
                    })
                } else {
                    this.share_tag_no = tag_no
                    this.share_gyms_id = '*'
                    this.share_gyms = '*'
                    this.share_gyms_ix = this.group_gyms.map((item, ix) => {
                        return ix
                    })
                    this.showModalShare = true
                }
            },
            shareAll() {
                this.$confirm("该操作将删除之前的连锁共享配置，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.share_gyms_id = '*'
                    this.share_gyms = '*'
                    this.share_gyms_ix = this.group_gyms.map((item, ix) => {
                        return ix
                    })
                })
            },
            changeShareGym(e) {
                this.share_gyms_id = []
                this.share_gyms = []
            },
            onSaveShareGym() {
                let _data = {
                    share_tag_no: this.share_tag_no,
                    tags_id: []
                }
                this[this.share_tag_no + 's_list'].forEach((item, ix) => {
                    if (item.is_selected) {
                        _data['tags_id'].push(item.id)
                    }
                })
                if (_data['tags_id'] && _data['tags_id'].length) {
                    _data['tags_id'] = JSON.stringify(_data['tags_id'])
                }
                if (this.share_gyms && '*' === this.share_gyms) {
                    _data['share_gyms_id'] = '*'
                } else {
                    if (this.share_gyms_ix && this.share_gyms_ix.length) {
                        _data['share_gyms_id'] = []
                        _data['share_gyms_id'] = this.share_gyms_ix.map((item, ix) => {
                            return this.group_gyms[item].id
                        })
                        if (_data['share_gyms_id'] && _data['share_gyms_id'].length) {
                            _data['share_gyms_id'] = JSON.stringify(_data['share_gyms_id'])
                        }
                    } else {
                        _data['share_gyms_id'] = null
                    }
                }
                this.$post("/admin/Train/setTagsShare", _data).then((res) => {
                    if (res.rst == 0) {
                        if ('bodypart' === this.share_tag_no) {
                            this.getBodypartList()
                        } else if ('mode' === this.share_tag_no) {
                            this.getModeList()
                        } else if ('course' === this.share_tag_no) {
                            this.getCourseList()
                        }
                        this.onCloseModalShare()
                    }
                })
            },
            onCloseModalShare() {
                this.share_tag_no = ''
                this.share_gyms_ix = []
                this.share_gyms_id = []
                this.share_gyms = []
                this.showModalShare = false
            }
        }
    }
</script>
<style lang="less" scoped>
    .acttag {
        .toolbar {
            margin: 50px 0 10px 0;

            .operation {
                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        /deep/.tag-item {
            margin: 10px;
            padding: 6px 10px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
        }

        /deep/.tag-item-rm {
            margin: 10px;
            padding: 6px 10px;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
            text-decoration-line: line-through;
            text-decoration-color: red;
            text-decoration-style: double;
        }
    }
</style>