<template>
    <div class="card">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入绑定课程名称" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">上架</el-radio-button>
                    <el-radio-button label="9">下架</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="cate_no" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button v-for="(item,ix) in cates" :key="ix" :label="ix">{{item}}</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="创建">
                <template slot-scope="scope">
                    <div style="text-align: center;">
                        <el-image :src="scope.row.avatar_url" fit="contain" style="width: 60px; height: 60px;"></el-image>
                        <div class="txtrow">{{ scope.row.create_dt | formatDt }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="类型 | 名称 ｜ 课程">
                <template slot-scope="scope">
                    <div class="txtrow">{{cates[scope.row.cate_no]}} | {{scope.row.name}}</div>
                    <div class="txtrow">自动添加进合同：{{1==scope.row.is_recmd?'是':'否'}}</div>
                    <div class="txtrow">课程：{{scope.row.course?scope.row.course.name:''}}</div>
                </template>
            </el-table-column>
            <el-table-column label="数量">
                <template slot-scope="scope">
                    <div class="txtrow">基本：{{scope.row.count}}{{'day'==scope.row.cate_no?'天':'次'}}</div>
                    <div class="txtrow">默认赠送：{{scope.row.count_gift_default}}{{'day'==scope.row.cate_no?'天':'次'}}</div>
                    <div class="txtrow">最大赠送：{{scope.row.count_gift_max}}{{'day'==scope.row.cate_no?'天':'次'}}</div>
                    <div class="txtrow">合计：{{scope.row.count+scope.row.count_gift_default}}~{{scope.row.count+scope.row.count_gift_max}}{{'day'==scope.row.cate_no?'天':'次'}}</div>
                </template>
            </el-table-column>
            <el-table-column label="价格">
                <template slot-scope="scope">
                    <div class="txtrow">原价：{{scope.row.price}}</div>
                    <div class="txtrow">一星：{{scope.row.price_level_1}}</div>
                    <div class="txtrow">二星：{{scope.row.price_level_2}}</div>
                    <div class="txtrow">三星：{{scope.row.price_level_3}}</div>
                    <div class="txtrow">四星：{{scope.row.price_level_4}}</div>
                    <div class="txtrow">五星：{{scope.row.price_level_5}}</div>
                </template>
            </el-table-column>
            <el-table-column label="有效期">
                <template slot-scope="scope">
                    <div class="txtrow">购买之日起</div>
                    <div class="txtrow">{{scope.row.expire_dayc}}天</div>
                </template>
            </el-table-column>
            <el-table-column label="默认备注">
                <template slot-scope="scope">
                    <div class="txtrow txtrow2">{{scope.row.desc}}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="50">
                <template slot-scope="scope">
                    <div class="txtrow" :class="1==scope.row.status?'fc_green':9==scope.row.status?'fc_orange':'fc_red'">{{ 1==scope.row.status ? '上架' : 9==scope.row.status ? '下架' : '删除' }}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="250" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="0==scope.row.status||9==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="handleCardStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="handleCardStatus(scope.row.id, 1)">上架</el-button>
                        <el-button v-bind:disabled="0==scope.row.status||9==scope.row.status" type="warn" size="small" @click="handleCardStatus(scope.row.id, 9)">下架</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

        <el-dialog class="form-wrap" :title="showAddModal ? '新增健身卡' : '修改健身卡'" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form @submit.native.prevent ref="card-form" :rules="rule" :model="cardOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="cardOnSet.name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="类型" prop="cate_no">
                            <el-select v-model="cardOnSet.cate_no" placeholder="请选择场馆" style="width: 100%;">
                                <el-option v-for="(item,no) in cates" :key="no" :label="item" :value="no"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="自动添加进合同" prop="is_recmd">
                            <el-radio-group v-model="cardOnSet.is_recmd">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="绑定课程">
                            <el-select v-model="cardOnSet.course_id" filterable remote clearable placeholder="请输入绑定课程名称" :remote-method="remoteMethod" :loading="loading" style="width: 100%;">
                                <el-option v-for="item in course_opts" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item :label="'数量（'+('day'==cardOnSet.cate_no?'天':'次')+'）'" prop="count">
                            <el-input v-model="cardOnSet.count" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item :label="'默认赠送（'+('day'==cardOnSet.cate_no?'天':'次')+'）'" prop="count_gift_default">
                            <el-input v-model="cardOnSet.count_gift_default" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item :label="'最大赠送（'+('day'==cardOnSet.cate_no?'天':'次')+'）'" prop="count_gift_max">
                            <el-input v-model="cardOnSet.count_gift_max" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="bc_golden" style="border-radius: 5px;">
                    <el-col :span="22" :offset="1">
                        <div class="fc_gray fs_small">注：教练星级越高、优惠越大、价格越便宜，没有优惠星级价格应设为0.00。</div>
                    </el-col>
                    <el-col :span="6" :offset="1">
                        <el-form-item label="原价" prop="price">
                            <el-input v-model="cardOnSet.price" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="一星价格" prop="price_level_1">
                            <el-input v-model="cardOnSet.price_level_1" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="二星价格" prop="price_level_2">
                            <el-input v-model="cardOnSet.price_level_2" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="1">
                        <el-form-item label="三星价格" prop="price_level_3">
                            <el-input v-model="cardOnSet.price_level_3" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="四星价格" prop="price_level_4">
                            <el-input v-model="cardOnSet.price_level_4" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="五星价格" prop="price_level_5">
                            <el-input v-model="cardOnSet.price_level_5" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="有效期（天）" prop="expire_dayc">
                            <el-input v-model="cardOnSet.expire_dayc" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="14" :offset="2">
                        <el-form-item label="备注" prop="desc">
                            <el-input v-model="cardOnSet.desc" placeholder="20字以内" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1">
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="onCloseModal">取消</el-button>
                            <el-button type="primary" @click="onSave">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    const defaultCard = {
        is_recmd: 0,
        count_gift_default: '',
        count_gift_max: ''
    }
    const defaultCourseOpts = [{
        id: 0,
        name: '无课程'
    }]
    export default {
		name:"course_card",
        data() {
            return {
                gym_id: 0,

                cates: {
                    gift: '赠卡',
                    trial: '体验卡',
                    times: '计次卡',
                    day: '时效卡',
                    team: '团卡'
                },

                cdit: "",
                loading: false,
                list: [],
                status: 1,
                cate_no: '',
                total: 0,
                page_ix: 1,
                page_size: 10,

                cardOnSet: {
                    ...defaultCard
                },
                course_opts: defaultCourseOpts,
                showAddModal: false,
                showSetModal: false,
                rule: {
                    name: [{
                        required: true,
                        message: '请输入健身卡名称',
                        trigger: ['blur', 'change']
                    }],
                    cate_no: [{
                        required: true,
                        message: '请选择健身卡类型',
                        trigger: ['blur', 'change']
                    }],
                    is_recmd: [{
                        validator: (rule, value, callback) => {
                            if (0 != value && 1 != value) {
                                callback(new Error('请选择是否自动添加进合同'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    count: [{
                        required: true,
                        message: '请选择数量',
                        trigger: ['blur', 'change']
                    }, {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请输入数量'));
                            }
                            if (!Number.isInteger(+value) || value <= 0) {
                                callback(new Error('请输入正整数数量'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    count_gift_default: [{
                        validator: (rule, value, callback) => {
                            if (!Number.isInteger(+value) || value < 0) {
                                callback(new Error('请输入正整数默认赠送数量'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    count_gift_max: [{
                        validator: (rule, value, callback) => {
                            if (!Number.isInteger(+value) || value < 0) {
                                callback(new Error('请输入正整数最大赠送数量'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price: [{
                        required: true,
                        message: '请输入价格',
                        trigger: ['blur', 'change']
                    }, {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请输入价格'));
                            }
                            if (/^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price_level_1: [{
                        validator: (rule, value, callback) => {
                            if (!value || /^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price_level_2: [{
                        validator: (rule, value, callback) => {
                            if (!value || /^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price_level_3: [{
                        validator: (rule, value, callback) => {
                            if (!value || /^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price_level_4: [{
                        validator: (rule, value, callback) => {
                            if (!value || /^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    price_level_5: [{
                        validator: (rule, value, callback) => {
                            if (!value || /^\d+(\.\d+)?$/.test(value)) {
                                callback();
                            } else {
                                callback(new Error('请输入正确的价格'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    expire_dayc: [{
                        required: true,
                        message: '请输入有效期多少天',
                        trigger: ['blur', 'change']
                    }, {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请输入有效期多少天'));
                            }
                            if (!Number.isInteger(+value) || value <= 0) {
                                callback(new Error('请输入正整数有效期多少天'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    desc: [{
                        validator: (rule, value, callback) => {
                            if (value && value.length > 20) {
                                callback(new Error('请输入20字以内的备注'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }]
                }
            }
        },
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    cate_no: this.cate_no,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                this.$post("/admin/Course/queryCard", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            goSet(card) {
                this.cardOnSet = {
                    ...card
                }
                if (card.course_id) {
                    this.course_opts = [{
                        id: card.course_id,
                        name: (card.course && card.course.name) ? card.course.name : '未知课程'
                    }]
                } else {
                    this.course_opts = defaultCourseOpts
                }
                this.showSetModal = true
            },
            remoteMethod(query) {
                setTimeout(() => {
                    this.loading = true;
                    this.$post("/admin/Course/remoteCardCourses", { gym_id: this.gym_id, cdit: query }).then(res => {
                        if (res.rst == 0) {
                            this.course_opts = res.data;
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }, 250)
            },
            onSave() {
                this.$refs['card-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存健身卡内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            this.loading = true;
                            let _data = {};
                            if (this.showModal) {
                                _data.gym_id = this.gym_id
                                _data.name = this.cardOnSet.name
                                _data.cate_no = this.cardOnSet.cate_no
                                _data.is_recmd = this.cardOnSet.is_recmd
                                _data.course_id = this.cardOnSet.course_id
                                _data.count = this.cardOnSet.count
                                _data.count_gift_default = this.cardOnSet.count_gift_default ? this.cardOnSet.count_gift_default : 0
                                _data.count_gift_max = this.cardOnSet.count_gift_max ? this.cardOnSet.count_gift_max : 0
                                _data.price = this.cardOnSet.price
                                _data.price_level_1 = this.cardOnSet.price_level_1 > 0.009 ? this.cardOnSet.price_level_1 : 0.0
                                _data.price_level_2 = this.cardOnSet.price_level_2 > 0.009 ? this.cardOnSet.price_level_2 : 0.0
                                _data.price_level_3 = this.cardOnSet.price_level_3 > 0.009 ? this.cardOnSet.price_level_3 : 0.0
                                _data.price_level_4 = this.cardOnSet.price_level_4 > 0.009 ? this.cardOnSet.price_level_4 : 0.0
                                _data.price_level_5 = this.cardOnSet.price_level_5 > 0.009 ? this.cardOnSet.price_level_5 : 0.0
                                _data.expire_dayc = this.cardOnSet.expire_dayc
                                _data.desc = this.cardOnSet.desc
                            }
                            if (this.showSetModal) {
                                _data.card_id = this.cardOnSet.id;
                            }
                            this.$post("/admin/Course/saveCard", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.getList();
                                    this.onCloseModal();
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        });
                    }
                });
            },
            handleCardStatus(card_id, status) {
                this.$confirm("该操作将" + (1 == status ? "上架" : (9 == status ? "下架" : "删除")) + "所选健身卡，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Course/saveCard", {
                        card_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseModal() {
                this.showAddModal = false
                this.showSetModal = false
                this.cardOnSet = {
                    ...defaultCard
                }
            }
        }
    };
</script>
<style lang="less" scoped>
    .card {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        /deep/.el-form-item__label {
            text-align: left;
            float: none;
            word-break: break-word;
        }
    }
</style>