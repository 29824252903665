<template>
    <div class="contract">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入甲方手机号或姓名" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="1">待初步审核</el-radio-button>
                    <el-radio-button label="3">待签字付款</el-radio-button>
                    <el-radio-button label="4">已付款待生效</el-radio-button>
                    <el-radio-button label="5">生效使用</el-radio-button>
                    <el-radio-button label="9">已拒绝</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-date-picker v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
            </div>
        </div>

        <div class="fc_gray fs_small">注：表中甲方、乙方、销售人员信息为创建合同时的信息，可能与当前信息不一致。</div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="时间" width="200">
                <template slot-scope="scope">
                    <div class="txtrow">{{ scope.row.create_dt | formatDt }} 创建</div>
                    <div v-if="scope.row.audits_record && scope.row.audits_record.length" class="txtrow">
                        <div v-for="(item,ix) in scope.row.audits_record">
                            <div class="txtrow">{{ item.audit_dt | formatDt }} {{3==item.status?'初审通过':5==item.status?'生效使用':9==item.status?'拒绝':''}}</div>
                            <div v-if="item.remark" class="txtrow">{{item.remark}}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="甲方 | 乙方 | 销售人员">
                <template slot-scope="scope">
                    <div class="txtrow">甲方：{{scope.row.party_a.name}} {{scope.row.party_a.phoneno}}</div>
                    <div class="txtrow">乙方：{{scope.row.party_b.name}}</div>
                    <div class="txtrow">销售：{{scope.row.saler.name}} {{scope.row.saler.phoneno}}</div>
                </template>
            </el-table-column>
            <el-table-column label="客户签字" width="100">
                <template slot-scope="scope">
                    <el-image style="width: 60px; height: 60px" :src="scope.row.sign_url" fit="fill"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="付款凭证" width="100">
                <template slot-scope="scope">
                    <el-image style="width: 60px; height: 60px" :src="scope.row.pay_url" fit="fill"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                    <div class="txtrow" :class="1==scope.row.status?'fc_blue':(3==scope.row.status||4==scope.row.status)?'fc_orange':5==scope.row.status?'fc_green':'fc_red'">{{ 1==scope.row.status ? '待初步审核' : 3==scope.row.status ? '待签字付款' : 4==scope.row.status ? '已付款待生效' : 5==scope.row.status ? '生效使用' :9==scope.row.status?'拒绝':'删除' }}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="1!=scope.row.status&&4!=scope.row.status" type="danger" size="small" @click="handleContractStatus(scope.row.id, 9)">拒绝</el-button>
                        <el-button v-bind:disabled="1!=scope.row.status" type="primary" size="small" @click="handleContractStatus(scope.row.id, 3)">初审</el-button>
                        <el-button v-bind:disabled="4!=scope.row.status" type="success" size="small" @click="handleContractStatus(scope.row.id, 5)">通过</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    export default {
		name:"order_contract",
        data() {
            return {
                gym_id: 0,

                drOpts: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date_range: '',

                cdit: "",
                loading: false,
                list: [],
                status: '',
                total: 0,
                page_ix: 1,
                page_size: 10
            };
        },
        computed: {
            ...mapState(["user"]),
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.date_range) {
                    _data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
                    _data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
                }
                this.$post("/admin/Order/queryContract", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            handleContractStatus(contract_id, status) {
                this.$confirm("该操作将" + (3 == status ? "初审通过" : (5 == status ? "生效使用" : (9 == status ? "拒绝" : ""))) + "所选合同，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Order/saveContract", {
                        contract_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
        }
    };
</script>
<style lang="less" scoped>
    .contract {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    width: 100px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .user-info {
            min-width: 600px;
            padding: 0px 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;

            .info {
                width: 45%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .label {
                    width: 120px;
                    flex: none;
                    color: #569ef8;
                    text-align: right;
                }

                .text {
                    flex: 1;
                    color: #333;
                }
            }

            .el-table {
                width: 90%;
                margin: 0 5%;
                flex: none;
                margin-bottom: 20px;
                border-radius: 0;

                thead th {
                    color: #569ef8;
                    font-size: 14px;
                }
            }
        }

        .el-dialog {
            min-width: 660px;
        }

        .tabs {
            padding: 0 10px;
        }

        .img-uplodaer {
            width: 195px;
            margin-right: 10px;

            .el-button {
                width: 195px;
                border-radius: 0;
            }

            .frame {
                width: 195px;
                height: 100px;
                border: 1px solid #d0d0d0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .el-select {
            width: 100%;
        }

        .bank-addr {
            .el-form-item__content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .el-bg-gt1 {
            margin-left: 10px;
        }
    }

    .cb-house-group {
        margin: 15px 0;

        .cb-house {
            margin: 10px 30px 10px 0;
        }
    }
</style>