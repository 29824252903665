<template>
    <div class="change">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输对修改目标内容或对外展示名、身份证姓名、原手机号" clearable style="width:460px;" @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
            </div>
        </div>
        <div class="fc_orange fs_small">注：登录页申请的变更未指定所在具体场馆，会员在个人中心申请变更、教练申请变更会员信息则仅展示当前场馆的变更申请。</div>
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation">
                <el-radio-group size="small" v-model="source" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="signupin">登录页申请变更</el-radio-button>
                    <el-radio-button label="vip">会员个人中心申请变更</el-radio-button>
                    <el-radio-button label="trainer">教练申请变更会员信息</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">待处理</el-radio-button>
                    <el-radio-button label="3">处理失败</el-radio-button>
                    <el-radio-button label="5">处理成功</el-radio-button>
                    <el-radio-button label="9">已拒绝</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="头像" width="80">
                <template slot-scope="scope">
                    <el-image fit="contain" :src="scope.row.user.avatar_url" style="width: 60px;height: 60px;border-radius: 5px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="当前账号信息">
                <template slot-scope="scope">
                    <div>姓名ENG：{{ scope.row.user.name }}（{{scope.row.user.nickname}} {{ scope.row.user.sex == 1 ? "男" : "女" }}）</div>
                    <div>双重账号：
                        <span v-if="scope.row.user.wechat_openid">{{scope.row.user.wechat_openid.substr(0,3)+'***'+scope.row.user.wechat_openid.substr(-3)}}</span>
                        <span v-if="scope.row.user.phoneno" style="margin-left: 5px;">{{ scope.row.user.phoneno }}</span>
                    </div>
                    <div>生日年龄：<span v-if="scope.row.user.birth_dt>0">{{ scope.row.user.birth_dt | formatDate}}<span v-if="scope.row.user.age>=0">（{{scope.row.user.age}}岁）</span></span></div>
                </template>
            </el-table-column>
            <el-table-column label="申请变更">
                <template slot-scope="scope">
                    <div>申请时间：{{scope.row.create_dt | formatDt}}</div>
                    <div>变更类型：{{1==scope.row.type?'登陆页面变更手机号':2==scope.row.type?'登陆页面变更微信':11==scope.row.type?'个人中心变更手机号':15==scope.row.type?'个人中心变更身份证姓名':18==scope.row.type?'个人中心变更出生日期':21==scope.row.type?'教练变更会员手机号':25==scope.row.type?'教练变更会员身份证姓名':''}}</div>
                    <div :class="scope.row.gym_id?'':'fc_red'">指定场馆：{{scope.row.gym_id?'指定':'未指定'}}</div>
                </template>
            </el-table-column>
            <el-table-column label="变更内容">
                <template slot-scope="scope">
                    <div v-if="18==scope.row.type">变 更 前：{{scope.row.from_value | formatDate}}</div>
                    <div v-else>变 更 前：{{scope.row.from_value}}</div>
                    <div v-if="18==scope.row.type">变 更 后：{{scope.row.to_value | formatDate}}</div>
                    <div v-else>变 更 后：{{scope.row.to_value}}</div>
                    <div>审核时间：{{scope.row.do_dt | formatDt}}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorblue' : (3==scope.row.status ? 'colororange' : (5==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colorred' : ''))))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '待处理' : (3==scope.row.status ? '处理失败' : (5==scope.row.status ? '处理成功' : (9==scope.row.status ? '已拒绝' : ''))))}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="1!=scope.row.status" type="danger" size="small" @click="setChange(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="1!=scope.row.status" type="success" size="small" @click="setChange(scope.row.id, 5)">同意</el-button>
                        <el-button v-bind:disabled="1!=scope.row.status" type="warning" size="small" @click="setChange(scope.row.id, 9)">拒绝</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix"></el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex"
    const defaultMgu = {}
    export default {
        name: "user_change",
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            }
        },
        data() {
            return {
                gym_id: 0,

                cdit: "",
                loading: false,
                list: [],
                source: '',
                status: "1",
                total: 0,
                page_ix: 1,
                page_size: 10
            }
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    source: this.source,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                this.$post("/admin/User/queryChange", _data).then(res => {
                    if (res.rst == 0) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            setChange(change_id, status) {
                if (0 != status && 5 != status && 9 != status) {
                    this.$message({
                        type: 'warning',
                        message: '变更错误'
                    })
                    return
                }
                this.$confirm(
                    "该操作将「" + (0 == status ? "删除" : (5 == status ? "同意" : (9 == status ? "拒绝" : ""))) + "」所选变更，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/setChange", {
                        gym_id: this.gym_id,
                        adminer_id: this.user.id,
                        change_id,
                        status,
                    }).then((res) => {
                        if (0 == res.rst) {
                            this.$message({
                                type: 'success',
                                message: res.msg
                            })
                        }
                        this.getList();
                    });
                });
            }
        }
    }
</script>
<style lang="less" scoped>
    .change {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        /deep/.el-form-item__label {
            text-align: left;
            float: none;
            word-break: break-word;
        }
    }
</style>