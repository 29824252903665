<template>
	<div class="protocollist">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入协议标题或编号" clearable
					@clear="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
						@click="onSearchClick">查询</el-button>
				</el-input>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column prop="no" label="编号" />
			<el-table-column prop="title" label="标题" />
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span
						:class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="scope.row.status == 0" type="default" size="small"
							@click="goSet(scope.row)">修改
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small"
							@click="handleProtocolStatus(scope.row.id, 0)">删除
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 1" type="success" size="small"
							@click="handleProtocolStatus(scope.row.id, 1)">正常
						</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar f-r-b">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
					v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
				</el-pagination>
			</div>
		</div>

		<!-- 修改弹窗 -->
		<el-dialog title="协议文本信息" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form class="form-wrap" @submit.native.prevent ref="protocol-form" v-model="protocolOnSet" :rules="rule"
				label-width="120px" label-position="left" style="padding: 30px 10px">
				<el-form-item label="编号" prop="no">
					<el-input v-model="protocolOnSet.no" />
				</el-form-item>
				<el-form-item label="标题" prop="title"> 
					<el-input v-model="protocolOnSet.title" />
				</el-form-item>
				<el-form-item label="协议内容" class="main-url" prop="content">
					<quill-editor ref="myQuillEditor" v-model="protocolOnSet.content" class="myQuillEditor"
						:options="quillOption" />
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="onSave">保存设置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import {
		quillEditor,
	} from 'vue-quill-editor';
	import quillConfig from '../../utils/quill-config-simple'
	export default {
		name: "globalset_protocol",
		components: {
			quillEditor
		},
		data() {
			return {
				cdit: "",
				loading: false,
				list: [],
				status: 1,
				total: 0,
				current_page: 1,
				per_page: 10,
				protocolOnSet: [],
				showAddModal: false,
				showSetModal: false,
				quillOption: quillConfig ,// 富文本配置文件
				rule: {
					no: [{
						required: true,
						message: '请输入编号',
						trigger: ['blur', 'change']
					}],
					title: [{
						required: true,
						message: '请输入标题',
						trigger: ['blur', 'change']
					}],
					content: [{
						required: true,
						message: '请输入内容',
						trigger: ['blur', 'change']
					}],
				}
			};
		},
		computed: {
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.current_page - 1) * this.per_page;
			},
		},
		methods: {
			getList() {
				this.loading = true;
				this.$post("/admin/Ctrlboard/queryProtocol", {
						cdit: this.cdit,
						status: this.status,
						page_ix: this.current_page,
						page_size: this.per_page,
					})
					.then(res => {
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onSearchClick() {
				this.current_page = 1;
				this.getList();
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
			goSet(protocol) {
				this.protocolOnSet = protocol
				this.showSetModal = true;
			},
			onCloseModal() {
				this.showAddModal = false
				this.showSetModal = false
				this.protocolOnSet = []
			},
			onSave() {
				this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					let data = {
						no: this.protocolOnSet.no,
						title: this.protocolOnSet.title,
						content: this.protocolOnSet.content
					}
					if (this.showSetModal) {
						data.protocol_id = this.protocolOnSet.id
					}
					this.$post("/admin/Ctrlboard/saveProtocol", data)
						.catch(err => {
							console.log(err);
						})
						.finally(() => {
							this.getList();
							this.onCloseModal();
						});
				});
			},
			handleProtocolStatus(protocol_id, status) {
				this.$confirm(
					"该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "info",
					}
				).then(() => {
					this.$post("/admin/Ctrlboard/saveProtocol", {
						protocol_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
		},
		mounted() {
			this.getList();
		},
	};
</script>
<style lang="less">
	.protocollist {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {
					width: 100px;
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}

			.tip {
				height: 20px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #eb5753;
			}
		}

		.form-wrap {
			.tip {
				color: #ed991f;
			}
		}

		.user-info {
			min-width: 600px;
			padding: 0px 10px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;

			.info {
				width: 45%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;

				.label {
					width: 120px;
					flex: none;
					color: #569ef8;
					text-align: right;
				}

				.text {
					flex: 1;
					color: #333;
				}
			}

			.el-table {
				width: 90%;
				margin: 0 5%;
				flex: none;
				margin-bottom: 20px;
				border-radius: 0;

				thead th {
					color: #569ef8;
					font-size: 14px;
				}
			}
		}

		.el-dialog {
			min-width: 660px;
		}

		.tabs {
			padding: 0 10px;
		}

		.img-uplodaer {
			width: 195px;
			margin-right: 10px;

			.el-button {
				width: 195px;
				border-radius: 0;
			}

			.frame {
				width: 195px;
				height: 100px;
				border: 1px solid #d0d0d0;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.bank-addr {
			.el-form-item__content {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.el-bg-gt1 {
			margin-left: 10px;
		}
	}
</style>
