<template>
	<div class="effect">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-select v-model="bodypart_ix" size="small" filterable remote clearable placeholder="请选择身体部位" :remote-method="remoteMethodBodypart" :loading="loading" @change="onSearchClick">
					<el-option v-for="(item,ix) in bodypart_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id && item.gym_id>0)?'（总部）':'')" :value="ix"></el-option>
				</el-select>
				<el-select v-model="mode_ix" size="small" filterable remote clearable placeholder="请选择动作模式" :remote-method="remoteMethodMode" :loading="loading" style="margin-left:10px;" @change="onSearchClick">
					<el-option v-for="(item,ix) in mode_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id && item.gym_id>0)?'（总部）':'')" :value="ix"></el-option>
				</el-select>
				<el-select v-model="course_ix" size="small" filterable remote clearable placeholder="请选择课程类型" :remote-method="remoteMethodCourse" :loading="loading" style="margin-left:10px;" @change="onSearchClick">
					<el-option v-for="(item,ix) in course_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id && item.gym_id>0)?'（总部）':'')" :value="ix"></el-option>
				</el-select>
				<el-button slot="append" class="search-btn" type="success" size="small" style="margin-left:10px;" @click="onSearchClick">查询</el-button>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" style="margin-left:50px;" @click="goAdd">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
		</div>

		<div class="fc_gray fs_small" style="display: flex;align-items: center;justify-content: flex-start;">
			<span>注：选择部位、模式、课程后搜索本场馆“<span class="bold">{{user.gym.name+(user.gym.group_sn?(1==user.gym.is_flagship?'（总部）':'（连锁）'):'（单店）')}}</span>”训练动作。</span>
			<span v-if="user.gym.group_sn">
				<span v-if="1==user.gym.is_flagship">
					<el-upload ref="upload" accept=".xls" name="excel" :data="uploadData" action="/admin/Train/importActExcel" :multiple="false" :show-file-list="false" :before-upload="beforeUpload" :on-success="uploadSuccess" :on-error="uploadError">
						<el-button size="small" type="text">总部还可以：Excel导入数据（不超过3M）</el-button>
					</el-upload>
				</span>
				<span v-else>
					连锁场馆还可以：<span :class="flagship_acts_isopen?'fc_orange':'fc_blue'" style="cursor:pointer;" @click="onFlagshipClick">{{flagship_acts_isopen?'隐藏':'查看'}}总部共享动作库</span>
				</span>
			</span>
			<el-button size="small" type="text" style="margin-left:25px;">
				<el-link :underline="false" style="color:#34A853;" :href="dlExcelUrl">导出场馆动作</el-link>
			</el-button>
		</div>

		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column label="时间">
				<template slot-scope="scope">
					<div class="txtrow">{{ scope.row.create_dt | formatDt }} 创建</div>
					<div class="txtrow">{{ scope.row.name }}</div>
					<div class="txtrow">
						<span>系统升级：{{ 1>scope.row.from_id?'否':'是' }}</span>
						<span style="margin-left:10px;">复合动作：{{ 1==scope.row.is_complex?'是':'否' }}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="分组｜阻力｜间休">
				<template slot-scope="scope">
					<div v-if="'times'===scope.row.ref_group_cateno" class="txtrow">分组：
						<span>计次</span>
						<span style="margin-left:10px;">{{scope.row.ref_group_count}}组*{{scope.row.ref_group_times}}次</span>
					</div>
					<div v-else-if="'dura'===scope.row.ref_group_cateno" class="txtrow">分组：
						<span>计时</span>
						<span style="margin-left:10px;">{{scope.row.ref_group_count}}组*{{scope.row.ref_group_dura | formatDuraMS}}</span>
					</div>
					<div v-else class="txtrow">分组：
						<span>未知</span>
						<span style="margin-left:10px;">{{scope.row.ref_group_count}}组</span>
					</div>
					<div class="txtrow">阻力：{{scope.row.ref_resistance}}{{scope.row.ref_resistance_unit}}</div>
					<div class="txtrow">间休：{{scope.row.ref_group_break_dura | formatDuraMS}}</div>
				</template>
			</el-table-column>
			<el-table-column label="部位｜模式｜课程类型">
				<template slot-scope="scope">
					<div class="txtrow">部位：{{(scope.row.tag_bodyparts && scope.row.tag_bodyparts.length) ? scope.row.tag_bodyparts.join(','):''}}</div>
					<div class="txtrow">模式：{{(scope.row.tag_modes && scope.row.tag_modes.length) ? scope.row.tag_modes.join(','):''}}</div>
					<div class="txtrow">课程：{{(scope.row.tag_courses && scope.row.tag_courses.length) ? scope.row.tag_courses.join(','):''}}</div>
				</template>
			</el-table-column>
			<el-table-column label="连锁共享">
				<template slot-scope="scope">
					<div v-if="user.gym.group_sn">
						<div v-if="1==user.gym.is_flagship">
							<div>总部：{{scope.row.share_gyms_id?('*'==scope.row.share_gyms_id?'与“所有”连锁共享':'与“'+scope.row.share_gyms_id.length+'”家场馆共享'):'“不”与连锁共享'}}</div>
							<div v-if="scope.row.share_gyms && '*'!=scope.row.share_gyms && scope.row.share_gyms.length" class="txtrow txtrow2" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
								<div v-for="(item,ix) in scope.row.share_gyms" :key="ix" style="margin-right: 10px;">{{item.name}}</div>
							</div>
						</div>
						<div v-else>连锁：{{scope.row.gym_id==gym_id?'本场馆自定义动作':'异常 本场馆自定义动作'}}</div>
					</div>
					<div v-else>单店：{{scope.row.gym_id==gym_id?'本场馆自定义动作':'异常 非本场馆自定义动作'}}</div>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="50">
				<template slot-scope="scope">
					<span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
						<el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="handleActStatus(scope.row.id, 0)">删除</el-button>
						<el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="handleActStatus(scope.row.id, 1)">正常</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

		<div :style="{'display':flagship_acts_isopen?'':'none'}">
			<div class="fc_orange fs_small" style="cursor:pointer;display: flex;align-items: center;justify-content: flex-end;" @click="onFlagshipClick">隐藏总部共享动作库</div>
			<el-table :data="flagship_acts" style="width: 100%" v-loading="loading" size="small" class="mb12">
				<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
				<el-table-column label="时间">
					<template slot-scope="scope">
						<div class="txtrow">{{ scope.row.create_dt | formatDt }} 创建</div>
						<div class="txtrow">{{ scope.row.name }}</div>
						<div class="txtrow">
							<span>系统升级：{{ 1>scope.row.from_id?'否':'是' }}</span>
							<span style="margin-left:10px;">复合动作：{{ 1==scope.row.is_complex?'是':'否' }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="分组｜阻力｜间休">
					<template slot-scope="scope">
						<div v-if="'times'===scope.row.ref_group_cateno" class="txtrow">分组：
							<span>计次</span>
							<span style="margin-left:10px;">{{scope.row.ref_group_count}}组*{{scope.row.ref_group_times}}次</span>
						</div>
						<div v-else-if="'dura'===scope.row.ref_group_cateno" class="txtrow">分组：
							<span>计时</span>
							<span style="margin-left:10px;">{{scope.row.ref_group_count}}组*{{scope.row.ref_group_dura | formatDuraMS}}</span>
						</div>
						<div v-else class="txtrow">分组：
							<span>未知</span>
							<span style="margin-left:10px;">{{scope.row.ref_group_count}}组</span>
						</div>
						<div class="txtrow">阻力：{{scope.row.ref_resistance}}{{scope.row.ref_resistance_unit}}</div>
						<div class="txtrow">间休：{{scope.row.ref_group_break_dura | formatDuraMS}}</div>
					</template>
				</el-table-column>
				<el-table-column label="部位｜模式｜课程类型">
					<template slot-scope="scope">
						<div class="txtrow">部位：{{(scope.row.tag_bodyparts && scope.row.tag_bodyparts.length) ? scope.row.tag_bodyparts.join(','):''}}</div>
						<div class="txtrow">模式：{{(scope.row.tag_modes && scope.row.tag_modes.length) ? scope.row.tag_modes.join(','):''}}</div>
						<div class="txtrow">课程：{{(scope.row.tag_courses && scope.row.tag_courses.length) ? scope.row.tag_courses.join(','):''}}</div>
					</template>
				</el-table-column>
				<el-table-column label="连锁共享状态">
					<template slot-scope="scope">
						<div>{{scope.row.share_gyms_id?('*'==scope.row.share_gyms_id?'与“所有”连锁共享':'与“'+scope.row.share_gyms_id.length+'”家场馆共享'):'“不”与连锁共享'}}</div>
						<div v-if="scope.row.share_gyms && '*'!=scope.row.share_gyms && scope.row.share_gyms.length" class="txtrow txtrow2" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
							<div v-for="(item,ix) in scope.row.share_gyms" :key="ix" style="margin-right: 10px;">{{item.name}}</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog class="form-wrap" :title="showAddModal ? '新增训练动作' : '修改训练动作'" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form class="form-wrap" @submit.native.prevent ref="act-form" :rules="rule" :model="actOnSet" label-position="left" style="padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="名称" prop="name">
							<el-input v-model="actOnSet.name" style="width:80%" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="复合动作">
							<el-radio-group v-model="actOnSet.is_complex">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" class="border bc_golden_bright" style="margin-bottom:25px;padding-top:25px;">
					<el-col :span="22" :offset="1">
						<el-form-item label="分组">
							<el-radio-group v-model="actOnSet.ref_group_cateno">
								<el-radio label="times">计次</el-radio>
								<el-radio label="dura">计时</el-radio>
							</el-radio-group>
							<span style="margin: 0 25px;">
								<span>
									<el-select v-model="group_count_ix" placeholder="请选择组数" style="width:20%;">
										<el-option v-for="(item,ix) in group_count_vals" :key="ix" :label="item" :value="ix">
										</el-option>
									</el-select>
									组
								</span>
								<span style="margin:0 5px"> * </span>
								<span v-if="'times'==actOnSet.ref_group_cateno">
									<el-select v-model="group_times_ix" placeholder="请选择次数" style="width:20%;">
										<el-option v-for="(item,ix) in group_times_vals" :key="ix" :label="item" :value="ix">
										</el-option>
									</el-select>
									次
								</span>
								<span v-else-if="'dura'==actOnSet.ref_group_cateno">
									<el-select v-model="group_dura_min_ix" placeholder="请选择时长" style="width:15%;">
										<el-option v-for="(item,ix) in group_dura_min_vals" :key="ix" :label="item" :value="ix">
										</el-option>
									</el-select>
									分
									<el-select v-model="group_dura_sec_ix" placeholder="请选择时长" style="width:15%;">
										<el-option v-for="(item,ix) in group_dura_sec_vals" :key="ix" :label="item" :value="ix">
										</el-option>
									</el-select>
									秒
								</span>
							</span>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="阻力">
							<el-input v-model="actOnSet.ref_resistance" style="width:80%" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="阻力单位">
							<el-radio-group v-model="resistance_unit_ix">
								<el-radio v-for="(item,ix) in resistance_units" :label="ix">{{item}}</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="间休">
							<el-select v-model="group_break_dura_min_ix" placeholder="请选择时长" style="width:35%;">
								<el-option v-for="(item,ix) in group_dura_min_vals" :key="ix" :label="item" :value="ix">
								</el-option>
							</el-select>
							分
							<el-select v-model="group_break_dura_sec_ix" placeholder="请选择时长" style="width:35%;">
								<el-option v-for="(item,ix) in group_dura_sec_vals" :key="ix" :label="item" :value="ix">
								</el-option>
							</el-select>
							秒
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="22" :offset="1">
						<el-form-item label="部位">
							<el-select v-model="tag_bodyparts_ix" filterable remote clearable multiple placeholder="请选择身体部位" :remote-method="remoteMethodBodypart" :loading="loading" style="width: 90%;">
								<el-option v-for="(item,ix) in tag_bodypart_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id)?'（总部）':'')" :value="ix"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="22" :offset="1">
						<el-form-item label="模式">
							<el-select v-model="tag_modes_ix" filterable remote clearable multiple placeholder="请选择运动模式" :remote-method="remoteMethodMode" :loading="loading" style="width: 90%;">
								<el-option v-for="(item,ix) in tag_mode_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id)?'（总部）':'')" :value="ix"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="22" :offset="1">
						<el-form-item label="课程">
							<el-select v-model="tag_courses_ix" filterable remote clearable multiple placeholder="请选择课程类型" :remote-method="remoteMethodCourse" :loading="loading" style="width: 90%;">
								<el-option v-for="(item,ix) in tag_course_opts" :key="ix" :label="item.name+((user.gym.group_sn && 1!=user.gym.is_flagship && gym_id!=item.gym_id)?'（总部）':'')" :value="ix"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="user.gym.group_sn && 1==user.gym.is_flagship" :gutter="20" class="border bc_golden_bright" style="margin-bottom:25px;padding-top:25px;">
					<el-col :span="22" :offset="1">
						<el-form-item label="">
							<div style="display: flex;align-items: center;justify-content: space-between;">
								<div>
									已与（
									<span v-if="actOnSet.share_gyms && '*'==actOnSet.share_gyms">所有</span>
									<span v-else>{{share_gyms_ix.length}}家</span>
									）连锁场馆共享
								</div>
								<el-button type="text" @click="shareAll">与所有连锁（包括以后加入的场馆）共享</el-button>
							</div>
							<el-select v-model="share_gyms_ix" filterable remote clearable multiple placeholder="请选择共享场馆" :remote-method="getGroupGyms" :loading="loading" style="width: 100%;" @change="changeShareGym">
								<el-option v-for="(item,ix) in group_gyms" :key="ix" :label="item.name" :value="ix"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="22" :offset="1">
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModal">取消</el-button>
							<el-button type="primary" @click="onSave">保存</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

	</div>
</template>
<script>
	import {
		mapState
	} from "vuex"
	const defaultBodypart = {
		id: 0,
		name: '不限身体部位',
		gym_id: 0
	}
	const defaultMode = {
		id: 0,
		name: '不限运动模式',
		gym_id: 0
	}
	const defaultCourse = {
		id: 0,
		name: '不限课程类型',
		gym_id: 0
	}
	const defaultAct = {
		name: '',
		is_complex: 0,
		ref_group_cateno: 'times',
		ref_group_count: 0,
		ref_group_times: 0,
		ref_group_dura: 0,
		ref_resistance: 0,
		ref_resistance_unit: '',
		tag_bodyparts: [],
		tag_modes: [],
		tag_course: [],
		share_gyms_id: null,
		share_gyms: null
	}
	export default {
		name: "act",
		data() {
			return {
				gym_id: 0,

				bodypart_ix: 0,
				bodypart_opts: [{
					...defaultBodypart
				}],
				mode_ix: 0,
				mode_opts: [{
					...defaultMode
				}],
				course_ix: 0,
				course_opts: [{
					...defaultCourse
				}],
				group_gyms: [],

				flagship_acts_isopen: false,
				flagship_acts: [],

				cdit: "",
				loading: false,
				list: [],
				status: 1,
				total: 0,
				page_ix: 1,
				page_size: 10,

				act_id: 0,
				actOnSet: {
					...defaultAct
				},
				showAddModal: false,
				showSetModal: false,
				rule: {
					name: [{
						required: true,
						message: '请输入动作名称',
						trigger: ['blur', 'change']
					}]
				},
				group_count_ix: 0,
				group_count_vals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
				group_times_ix: 0,
				group_times_vals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
				group_dura_min_ix: 0,
				group_dura_min_vals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
				group_dura_sec_ix: 0,
				group_dura_sec_vals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
				group_break_dura_min_ix: 0,
				group_break_dura_sec_ix: 0,
				resistance_unit_ix: 0,
				resistance_units: ['KG', 'LBS', '级'],
				tag_bodyparts_ix: [],
				tag_bodypart_opts: [],
				tag_modes_ix: [],
				tag_mode_opts: [],
				tag_courses_ix: [],
				tag_course_opts: [],
				share_gyms_ix: [],
				
				dlExcelUrl: '/admin/Train/dlActExcel?excel_useno=trainact_list',
				uploadData: {
					ext: ''
				},
				has_import: false,
				open_import_rst: false,
				rst_list: []
			};
		},
		computed: {
			...mapState(["user"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			}
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.dlExcelUrl = '/admin/Train/dlActExcel?excel_useno=trainact_list&gym_id='+this.gym_id
			this.remoteMethodBodypart()
			this.remoteMethodMode()
			this.remoteMethodCourse()
			this.getGroupGyms()
			this.getList()
		},
		methods: {
			// 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
			beforeUpload(file) {
				//文件类型
				const isType = file.type === 'application/vnd.ms-excel'
				const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
				const fileType = isType || isTypeComputer
				if (!fileType) {
					this.$message.error('上传文件只能是xls格式！')
				}
				// 文件大小限制为10M
				const fileLimit = file.size / 1024 / 1024 < 3;
				if (!fileLimit) {
					this.$message.error('上传文件大小不超过3M！');
				}
				this.uploadData.ext = file.name.substring(file.name.lastIndexOf(".") + 1)
				this.has_import = false
				this.open_import_rst = false
				this.rst_list = []
				return fileType && fileLimit
			},
			uploadSuccess(res, file) {
				if (res) {
					if (0 == res.rst) {
						this.$message.success(res.msg)
					} else {
						this.$message.error(res.msg)
					}
					if (0 == res.rst || -1 == res.rst) {
						this.has_import = true
						this.open_import_rst = res.data.length > 100 ? false : true
						this.rst_list = res.data
					}
				} else {
					this.$message.error('导入失败')
				}
			},
			uploadError(res, file) {
				this.$message.error('上传失败')
			},
			remoteMethodBodypart(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Train/getActbodyparts", {
						gym_id: this.gym_id
					}).then(res => {
						if (res.rst == 0) {
							this.bodypart_opts = [{
								...defaultBodypart
							}]
							this.tag_bodypart_opts = []
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.bodypart_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
									this.tag_bodypart_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			remoteMethodMode(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Train/getActmodes", {
						gym_id: this.gym_id
					}).then(res => {
						if (res.rst == 0) {
							this.mode_opts = [{
								...defaultMode
							}]
							this.tag_mode_opts = []
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.mode_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
									this.tag_mode_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			remoteMethodCourse(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Train/getActcourses", {
						gym_id: this.gym_id
					}).then(res => {
						if (res.rst == 0) {
							this.course_opts = [{
								...defaultCourse
							}]
							this.tag_course_opts = []
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.course_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
									this.tag_course_opts.push({
										id: item.id,
										name: item.name,
										gym_id: item.gym_id
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			getGroupGyms() {
				this.loading = true;
				this.$post("/admin/Train/getGroupGyms", {
					gym_id: this.gym_id
				}).then(res => {
					if (res.rst == 0) {
						this.group_gyms = res.data
					}
				}).finally(() => {
					this.loading = false;
				})
			},
			onFlagshipClick() {
				this.flagship_acts_isopen = !this.flagship_acts_isopen
				if (this.flagship_acts_isopen && !this.flagship_acts.length) {
					let _data = {
						gym_id: this.gym_id
					}
					this.$post("/admin/Train/getGymFlagshipActs", _data)
						.then(res => {
							if (res.rst == 0) {
								this.flagship_acts = res.data
							}
						})
				}
			},
			getList() {
				this.loading = true;
				let _data = {
					gym_id: this.gym_id,
					status: this.status,
					page_ix: this.page_ix,
					page_size: this.page_size
				}
				if (this.bodypart_ix > 0) {
					_data['cdit_bodypart'] = this.bodypart_opts[this.bodypart_ix].name
				}
				if (this.mode_ix > 0) {
					_data['cdit_mode'] = this.mode_opts[this.mode_ix].name
				}
				if (this.course_ix > 0) {
					_data['cdit_course'] = this.course_opts[this.course_ix].name
				}
				this.$post("/admin/Train/queryAct", _data)
					.then(res => {
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},

			onAllClick() {
				this.query_no = 'all'
				this.bodypart_ix = 0
				this.mode_ix = 0
				this.course_ix = 0
				this.page_ix = 1
				this.page_size = 1000
				this.getList()
			},
			onSearchClick() {
				this.query_no = 'query'
				this.page_ix = 1;
				this.getList();
			},
			onPageChange(page) {
				this.page_ix = page;
				this.getList();
			},
			goAdd() {
				this.act_id = 0
				this.actOnSet = {
					...defaultAct
				}
				this.group_count_ix = 0
				this.group_times_ix = 0
				this.group_dura_min_ix = 0
				this.group_dura_sec_ix = 0
				this.resistance_unit_ix = 0
				this.group_break_dura_min_ix = 0
				this.group_break_dura_sec_ix = 0
				this.tag_bodyparts_ix = []
				this.tag_modes_ix = []
				this.tag_courses_ix = []
				this.share_gyms_ix = this.group_gyms.map((item, ix) => {
					return ix
				})
				this.showAddModal = true
			},
			goSet(act) {
				this.act_id = act.id
				this.actOnSet = JSON.parse(JSON.stringify(act))
				this.group_count_ix = 0
				this.group_times_ix = 0
				this.group_dura_min_ix = 0
				this.group_dura_sec_ix = 0
				this.resistance_unit_ix = 0
				this.group_break_dura_min_ix = 0
				this.group_break_dura_sec_ix = 0
				this.tag_bodyparts_ix = []
				this.tag_modes_ix = []
				this.tag_courses_ix = []
				this.share_gyms_ix = []
				this.group_count_vals.forEach((item, ix) => {
					if (item == this.actOnSet.ref_group_count) {
						this.group_count_ix = ix
					}
				})
				this.group_times_vals.forEach((item, ix) => {
					if (item == this.actOnSet.ref_group_times) {
						this.group_times_ix = ix
					}
				})
				const min = Math.floor(this.actOnSet.ref_group_dura / 60)
				this.group_dura_min_vals.forEach((item, ix) => {
					if (item == min) {
						this.group_dura_min_ix = ix
					}
				})
				const sec = this.actOnSet.ref_group_dura % 60
				this.group_dura_sec_vals.forEach((item, ix) => {
					if (item == sec) {
						this.group_dura_sec_ix = ix
					}
				})
				this.resistance_units.forEach((item, ix) => {
					if (item.toLowerCase() == this.actOnSet.ref_resistance_unit.toLowerCase()) {
						this.resistance_unit_ix = ix
					}
				})
				const min_b = Math.floor(this.actOnSet.ref_group_break_dura / 60)
				this.group_dura_min_vals.forEach((item, ix) => {
					if (item == min_b) {
						this.group_break_dura_min_ix = ix
					}
				})
				const sec_b = this.actOnSet.ref_group_break_dura % 60
				this.group_dura_sec_vals.forEach((item, ix) => {
					if (item == sec_b) {
						this.group_break_dura_sec_ix = ix
					}
				})
				if (this.actOnSet.tag_bodyparts && this.actOnSet.tag_bodyparts.length) {
					this.actOnSet.tag_bodyparts.forEach((item, ix) => {
						this.tag_bodypart_opts.forEach((tag, tx) => {
							if (tag.name == item) {
								this.tag_bodyparts_ix.push(tx)
							}
						})
					})
				}
				if (this.actOnSet.tag_modes && this.actOnSet.tag_modes.length) {
					this.actOnSet.tag_modes.forEach((item, ix) => {
						this.tag_mode_opts.forEach((tag, tx) => {
							if (tag.name == item) {
								this.tag_modes_ix.push(tx)
							}
						})
					})
				}
				if (this.actOnSet.tag_courses && this.actOnSet.tag_courses.length) {
					this.actOnSet.tag_courses.forEach((item, ix) => {
						this.tag_course_opts.forEach((tag, tx) => {
							if (tag.name == item) {
								this.tag_courses_ix.push(tx)
							}
						})
					})
				}
				let find_ix = -1
				if (this.actOnSet.share_gyms) {
					if ('*' === this.actOnSet.share_gyms) {
						this.share_gyms_ix = this.group_gyms.map((item, ix) => {
							return ix
						})
					} else {
						if (this.actOnSet.share_gyms.length) {
							let group_gyms_id = this.group_gyms.map((group_gym, ggx) => {
								return group_gym.id
							})
							this.actOnSet.share_gyms.forEach((item, ix) => {
								find_ix = group_gyms_id.indexOf(item.id)
								if (find_ix > -1) {
									this.share_gyms_ix.push(find_ix)
								}
							})
						}
					}
				}
				this.showSetModal = true;
			},
			shareAll() {
				this.$confirm("该操作将删除之前的连锁共享配置，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.actOnSet.share_gyms_id = '*'
					this.actOnSet.share_gyms = '*'
					this.share_gyms_ix = this.group_gyms.map((item, ix) => {
						return ix
					})
				})
			},
			changeShareGym(e) {
				this.actOnSet.share_gyms_id = []
				this.actOnSet.share_gyms = []
			},
			onSave() {
				this.$refs['act-form'].validate((valid) => {
					if (valid) {
						this.$confirm("该操作将修改训练动作信息，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							this.loading = true;
							let _data = {
								name: this.actOnSet.name,
								is_complex: this.actOnSet.is_complex,
								ref_group_cateno: this.actOnSet.ref_group_cateno,
								ref_group_count: this.group_count_vals[this.group_count_ix], //this.actOnSet.ref_group_count,
								ref_group_times: this.group_times_vals[this.group_times_ix],
								ref_group_dura: 60 * this.group_dura_min_vals[this.group_dura_min_ix] + this.group_dura_sec_vals[this.group_dura_sec_ix],
								ref_resistance: this.actOnSet.ref_resistance,
								ref_resistance_unit: this.resistance_units[this.resistance_unit_ix].toLowerCase(),
								ref_group_break_dura: 60 * this.group_dura_min_vals[this.group_break_dura_min_ix] + this.group_dura_sec_vals[this.group_break_dura_sec_ix]
							}
							let bodyparts = []
							if (this.tag_bodyparts_ix && this.tag_bodyparts_ix.length) {
								this.tag_bodyparts_ix.forEach((item, ix) => {
									bodyparts.push(this.tag_bodypart_opts[item].name)
								})
							}
							_data['tag_bodyparts'] = (bodyparts && bodyparts.length) ? JSON.stringify(bodyparts) : ''
							let modes = []
							if (this.tag_modes_ix && this.tag_modes_ix.length) {
								this.tag_modes_ix.forEach((item, ix) => {
									modes.push(this.tag_mode_opts[item].name)
								})
							}
							_data['tag_modes'] = (modes && modes.length) ? JSON.stringify(modes) : ''
							let courses = []
							if (this.tag_courses_ix && this.tag_courses_ix.length) {
								this.tag_courses_ix.forEach((item, ix) => {
									courses.push(this.tag_course_opts[item].name)
								})
							}
							_data['tag_courses'] = (courses && courses.length) ? JSON.stringify(courses) : ''
							if (this.user.gym.group_sn && 1 == this.user.gym.is_flagship) {
								if (this.actOnSet.share_gyms && '*' === this.actOnSet.share_gyms) {
									_data['share_gyms_id'] = '*'
								} else {
									if (this.share_gyms_ix && this.share_gyms_ix.length) {
										_data['share_gyms_id'] = []
										_data['share_gyms_id'] = this.share_gyms_ix.map((item, ix) => {
											return this.group_gyms[item].id
										})
										if (_data['share_gyms_id'] && _data['share_gyms_id'].length) {
											_data['share_gyms_id'] = JSON.stringify(_data['share_gyms_id'])
										}
									} else {
										_data['share_gyms_id'] = null
									}
								}
							} else {
								_data['share_gyms_id'] = null
							}
							if (this.act_id) {
								_data['act_id'] = this.act_id
							} else {
								_data['gym_id'] = this.gym_id
							}
							this.$post("/admin/Train/saveAct", _data).then((res) => {
								if (res.rst == 0) {
									this.getList();
									this.onCloseModal();
								}
							}).finally(() => {
								this.loading = false;
							})
						})
					}
				})
			},
			handleActStatus(act_id, status) {
				this.$confirm("该操作将" + (1 == status ? "恢复" : (0 == status ? "删除" : "恢复/删除")) + "所选训练动作，且不可撤销，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/Train/saveAct", {
						act_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
			onCloseModal() {
				this.showAddModal = false;
				this.showSetModal = false;
				this.actOnSet = {
					...defaultAct
				};
			}
		}
	};
</script>
<style lang="less" scoped>
	.effect {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					width: 100px;
				}
			}

			.filters {
				margin-left: 50px;
			}

			.tip {
				height: 20px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #eb5753;
			}
		}

		.form-wrap {
			.tip {
				color: #ed991f;
			}
		}
	}
</style>