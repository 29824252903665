<template>
	<div class="adminer">
		<div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
			<div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入员工手机号或姓名" clearable @clear="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
				</el-input>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column label="头像" width="80">
				<template slot-scope="scope">
					<el-image fit="contain" :src="scope.row.adminer.avatar_url" style="width: 60px;height: 60px;border-radius: 5px;"></el-image>
				</template>
			</el-table-column>
			<el-table-column label="账号信息">
				<template slot-scope="scope">
					<div>姓名ENG：{{ scope.row.adminer.name }}（{{scope.row.adminer.nickname}} {{ scope.row.adminer.sex == 1 ? "男" : "女" }}）</div>
					<div>双重账号：
						<span v-if="scope.row.adminer.wechat_openid">{{scope.row.adminer.wechat_openid.substr(0,3)+'***'+scope.row.adminer.wechat_openid.substr(-3)}}</span>
						<span v-if="scope.row.adminer.phoneno" style="margin-left: 5px;">{{ scope.row.adminer.phoneno }}</span>
					</div>
					<div>生日年龄：<span v-if="scope.row.adminer.birth_dt>0">{{ scope.row.adminer.birth_dt | formatDate}}<span v-if="scope.row.adminer.age>=0">（{{scope.row.adminer.age}}岁）</span></span></div>
				</template>
			</el-table-column>
			<el-table-column label="加入场馆">
				<template slot-scope="scope">
					<div>加入时间：{{scope.row.create_dt | formatDate}}</div>
					<div>
						<span>是否教练：{{1==scope.row.is_trainer?'是':'否'}}</span>
						<span><i v-if="1==scope.row.is_trainer" v-for="th in 6" :key="th" class="el-icon-star-off" :class="th>scope.row.trainer_level?'fc_gray':'fc_orange'" style="margin-left:5px;"></i></span>
					</div>
					<div>
						<span v-if="1==scope.row.is_trainer" style="margin-right:25px;">教练对外展示：{{1==scope.row.trainer_isshow?'是':'否'}}</span>
						<span>手机老板：{{1==scope.row.is_super?'是':'否'}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="50">
				<template slot-scope="scope">
					<span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
						<el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="onStatus(scope.row.id, 0)">删除</el-button>
						<el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="onStatus(scope.row.id, 1)">正常</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="form-wrap" :title="showAddModal ? '新增员工' : '修改员工'" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form class="form-wrap" @submit.native.prevent ref="user-form" :rules="rule" :model="mguOnSet" label-position="left" style="padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="手机号" prop="adminer_phoneno">
							<el-input v-model="mguOnSet.adminer_phoneno" :disabled="showSetModal" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="小程序微信账号" prop="adminer_wechat_openid">
							<el-input v-model="mguOnSet.adminer_wechat_openid" disabled />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="姓名" prop="adminer_name">
							<el-input v-model="mguOnSet.adminer_name" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="昵称/英文名">
							<el-input v-model="mguOnSet.adminer_nickname" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="5" :offset="1">
						<el-form-item label="性别" style="height: 80px;">
							<div style="height:40px;">
								<el-radio-group v-model="mguOnSet.adminer_sex">
									<el-radio :label="1">男</el-radio>
									<el-radio :label="0">女</el-radio>
								</el-radio-group>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="8" :offset="2">
						<el-form-item label="是否教练" style="height: 80px;">
							<div style="height:40px;display: flex;align-items: center;justify-content: space-between;">
								<el-radio-group v-model="mguOnSet.is_trainer">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
								<i v-if="mguOnSet.is_trainer" v-for="th in 6" :key="th" class="el-icon-star-off" :class="th>mguOnSet.trainer_level?'fc_gray':'fc_orange'" @click="mguOnSet.trainer_level=th;"></i>
							</div>
						</el-form-item>
					</el-col>
					<el-col v-if="mguOnSet.is_trainer" :span="5" :offset="2">
						<el-form-item label="对外展示" style="height: 80px;">
							<div style="height:40px;">
								<el-radio-group v-model="mguOnSet.trainer_isshow">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="5" :offset="1">
						<el-form-item label="出生生日" prop="birth_dt">
							<el-date-picker v-model="mguOnSet.adminer_birth_dt" value-format="timestamp" type="date" placeholder="选择日期" style="width: 100%;"></el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="8" :offset="2">
						<el-form-item label="是否员工" style="height: 80px;">
							<div style="height:40px;">
								<el-radio-group v-model="mguOnSet.is_adminer">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="5" :offset="2">
						<el-form-item label="是否老板" style="height: 80px;">
							<div style="height:40px;">
								<el-radio-group v-model="mguOnSet.is_super">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</div>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
						<el-form-item label="" :style="{'visibility':(showAddModal||!mguOnSet.adminer_wechat_openid)?'hidden':''}">
							<el-button plain @click="initPassword">重置密码</el-button>
							<el-button plain @click="initPayPassword">重置支付密码</el-button>
						</el-form-item>
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModal">取消</el-button>
							<el-button type="primary" @click="onSave">保存设置</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

	</div>
</template>
<script>
	import {
		hex,
		hex_hmac
	} from "@/utils/hex.js"
	import {
		mapState
	} from "vuex"
	import {
		randStr
	} from "@/utils/formatData"
	const defaultMgu = {}
	export default {
		name: "user_adminer",
		computed: {
			...mapState(["user"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			},
		},
		data() {
			return {
				gym_id: 0,

				cdit: "",
				loading: false,
				list: [],
				status: "1",
				total: 0,
				page_ix: 1,
				page_size: 10,

				mguOnSet: {
					...defaultMgu
				},
				showAddModal: false,
				showSetModal: false,
				rule: {
					adminer_phoneno: [{
						required: true,
						message: '请输入员工手机号',
						trigger: ['blur', 'change']
					}, {
						validator: (rule, value, callback) => {
							if (/^1[3456789]\d{9}$/.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确的手机号'));
							}
						},
						trigger: ['blur', 'change']
					}],
					adminer_name: [{
						required: true,
						message: '请输入员工姓名',
						trigger: ['blur', 'change']
					}]
				}
			};
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.getList()
		},
		methods: {
			getList() {
				this.loading = true;
				let _data = {
					gym_id: this.gym_id,
					cdit: this.cdit,
					status: this.status,
					page_ix: this.page_ix,
					page_size: this.page_size
				}
				this.$post("/admin/User/queryAdminer", _data).then(res => {
					if (res.rst == 0) {
						this.list = res.data.data;
						this.total = res.data.total;
					}
				}).finally(() => {
					this.loading = false;
				})
			},
			onSearchClick() {
				this.page_ix = 1;
				this.getList();
			},
			onPageChange(page) {
				this.page_ix = page;
				this.getList();
			},
			goSet(mgu) {
				this.mguOnSet = {
					adminer_id: mgu.adminer.id,
					adminer_phoneno: mgu.adminer.phoneno,
					adminer_wechat_openid: mgu.adminer.wechat_openid,
					adminer_name: mgu.adminer.name,
					adminer_nickname: mgu.adminer.nickname,
					adminer_sex: mgu.adminer.sex,
					adminer_birth_dt: mgu.adminer.birth_dt ? 1000 * mgu.adminer.birth_dt : '',
					adminer_password: '',
					adminer_pay_password: '',
					mgu_id: mgu.id,
					is_trainer: mgu.is_trainer,
					trainer_level: mgu.trainer_level,
					trainer_isshow: mgu.trainer_isshow,
					is_adminer: mgu.is_adminer,
					is_super: mgu.is_super
				}
				this.showSetModal = true;
			},
			initPassword() {
				const pw = randStr(8)
				this.$confirm("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/User/saveAdminer", {
						adminer_id: this.mguOnSet.adminer_id,
						password: hex_hmac(String(this.mguOnSet.adminer_wechat_openid), hex(pw))
					}).finally(() => {
						this.onCloseModal();
					});
				})
			},
			initPayPassword() {
				const pw = randStr(8)
				this.$confirm("该操作将重置支付密码为 " + pw + "，确认记录后继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/User/saveAdminer", {
						adminer_id: this.mguOnSet.adminer_id,
						pay_password: hex_hmac(String(this.mguOnSet.adminer_wechat_openid), hex(pw))
					}).finally(() => {
						this.onCloseModal();
					});
				})
			},
			onSave() {
				this.$refs['user-form'].validate((valid) => {
					if (valid) {
						this.$confirm("该操作将修改员工信息，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							this.loading = true;
							let _data = {
								adminer_id: this.mguOnSet.adminer_id,
								name: this.mguOnSet.adminer_name,
								nickname: this.mguOnSet.adminer_nickname,
								sex: this.mguOnSet.adminer_sex,
								birth_dt: Math.floor(this.mguOnSet.adminer_birth_dt / 1000),
								mgu_id: this.mguOnSet.mgu_id,
								is_trainer: this.mguOnSet.is_trainer,
								trainer_level: this.mguOnSet.trainer_level,
								trainer_isshow: this.mguOnSet.trainer_isshow,
								is_adminer: this.mguOnSet.is_adminer,
								is_super: this.mguOnSet.is_super
							}
							this.$post("/admin/User/saveAdminer", _data).then((res) => {
								if (res.rst == 0) {
									this.getList();
									this.onCloseModal();
								}
							}).finally(() => {
								this.loading = false;
							})
						})
					}
				})
			},
			onStatus(mgu_id, status) {
				this.$confirm(
					"该操作将「" + (0 == status ? "删除" : "恢复") + "」所选员工，是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "info",
					}
				).then(() => {
					this.$post("/admin/User/saveAdminer", {
						mgu_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
			onCloseModal() {
				this.showAddModal = false;
				this.showSetModal = false;
				this.mguOnSet = {
					...defaultMgu
				};
			}
		}
	};
</script>
<style lang="less" scoped>
	.adminer {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}
		}

		/deep/.el-form-item__label {
			text-align: left;
			float: none;
			word-break: break-word;
		}
	}
</style>