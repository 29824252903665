<template>
	<div class="gym">
		<div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
			<div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入场馆名称或联系电话,或连锁品牌" clearable @change="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
				</el-input>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="9">停止营业</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
		</div>

		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
			<el-table-column label="连锁品牌">
				<template slot-scope="scope">
					<div v-if="scope.row.group_sn">
						<div class="txtrow" style="display: flex;align-items: center;justify-content: flex-start;">
							认证码：{{scope.row.group_sn}}
							<el-button v-if="scope.row.is_flagship" type="text" class="fc_golden txtrow" style="margin-left:5px;display: flex;align-items: center;justify-content: flex-start;" @click="goOutFlagship(scope.row.id)"><i class="el-icon-s-flag"></i>总部</el-button>
							<el-button v-else type="text" class="txtrow" style="padding:0;margin-left:5px;" @click="outGroup(scope.row.id)">退出</el-button>
						</div>
						<div class="txtrow" style="display: flex;align-items: center;justify-content: flex-start;">
							品牌名：<el-button type="text" class="txtrow" style="padding:0;" @click="changeGroupName(scope.row.id,scope.row.group_name,scope.row.is_flagship)">{{scope.row.group_name}}</el-button>
						</div>
					</div>
					<div v-else>
						<el-button size="mini" round @click="newGroup(scope.row.id)">创建总部</el-button>
						<el-button type="primary" size="mini" round @click="inGroup(scope.row.id)">加入连锁</el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="名称｜联系方式">
				<template slot-scope="scope">
					<div class="txtrow">{{scope.row.name}}</div>
					<div v-if="scope.row.tel_no || scope.row.tel_name" class="txtrow">{{scope.row.tel_no?scope.row.tel_no:''}} {{scope.row.tel_name ? scope.row.tel_name : ''}}</div>
				</template>
			</el-table-column>
			<el-table-column label="手机端会员｜教练｜员工">
				<template slot-scope="scope">
					<div class="txtrow">{{scope.row.vip_count}}人｜{{scope.row.trainer_count}}人｜{{scope.row.adminer_count}}人</div>
				</template>
			</el-table-column>
			<el-table-column label="场馆后台超级管理员">
				<template slot-scope="scope">
					<div v-if="scope.row.supers && scope.row.supers.length">
						<div v-if="scope.row.supers.length>3">
							<div v-for="ix in 3" :key="ix">
								<el-button type="text" class="txtrow" style="padding:3px 0;" @click="goSetSu(scope.row.supers[ix])">{{scope.row.supers[ix].name+' '+scope.row.supers[ix].phoneno}}</el-button>
							</div>
						</div>
						<div v-else="scope.row.supers.length>1">
							<div v-for="(item,ix) in scope.row.supers" :key="ix">
								<el-button type="text" class="txtrow" style="padding:3px 0;" @click="goSetSu(item)">{{item.name+' '+item.phoneno}}</el-button>
							</div>
						</div>
					</div>
					<el-button type="text" style="padding:3px 0;" @click="gym_id_su=scope.row.id;showAddModalSu = true;">新增超管</el-button>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="50">
				<template slot-scope="scope">
					<span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' :''))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : (9==scope.row.status ? '停业' : ''))}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="250" align="center">
				<template slot-scope="scope">
					<el-button-group v-if="1==scope.row.is_flagship">
						<el-button type="default" size="small" @click="toSortGroupGyms(scope.row.id)">连锁场馆排序</el-button>
					</el-button-group>
					<el-button-group v-else>
						<el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
						<el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleGymStatus(scope.row.id, 0)">删除</el-button>
						<el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleGymStatus(scope.row.id, 9)">停业</el-button>
						<el-button v-bind:disabled="scope.row.status == 1" type="success" size="small" @click="handleGymStatus(scope.row.id, 1)">正常</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="form-wrap" :title="showAddModal ? '新增场馆' : '修改场馆'" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form @submit.native.prevent ref="gym-form" :rules="rule" :model="gymOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="名称" prop="name">
							<el-input v-model="gymOnSet.name" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="联系电话" prop="tel_no">
							<el-input v-model="gymOnSet.tel_no" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="联系人" prop="tel_name">
							<el-input v-model="gymOnSet.tel_name" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="showAddModal" :gutter="20" class="border bc_golden_bright" style="margin-bottom:25px;padding-top:25px;">
					<el-col :span="22" :offset="1">
						<div class="fc_red" style="margin-bottom: 10px;">注意：品牌<span class="fs_large bold">总部</span>一旦创建，将不能删除或更改！！！<span class="fs_large bold">总部</span>为连锁统一提供动作、课程等资源支持！！！</div>
					</el-col>
					<el-col :span="10" :offset="1">
						<el-form-item label="">
							<div style="display: flex;align-items: center;justify-content: flex-start;">
								<div style="margin-right:25px;">创建品牌总部</div>
								<el-radio-group v-model="gymOnSet.is_flagship">
									<el-radio :label="1">是</el-radio>
									<el-radio :label="0">否</el-radio>
								</el-radio-group>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="">
							<el-input v-model="gymOnSet.group_name" placeholder="品牌名" />
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row v-else-if="gymOnSet.group_sn" :gutter="20" class="border bc_golden_bright" style="margin-bottom:25px;padding-top:25px;">
					<el-col :span="10" :offset="1">
						<el-form-item label="" prop="group_sn">
							<div style="display: flex;align-items: center;justify-content: space-between;">
								<div>连锁品牌名<small class="fc_gray">（{{gymOnSet.group_sn}}）</small></div>
								<el-button type="text" style="margin-left:25px;" @click="outGroup">退出</el-button>
							</div>
							<div>
								<el-input v-model="gymOnSet.group_name" />
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="是否总部">
							<el-radio-group v-model="gymOnSet.is_flagship" style="width: 100%;">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="22" :offset="1">
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModal">取消</el-button>
							<el-button type="primary" @click="onSave">保存</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

		<el-dialog class="form-wrap" :title="showAddModalSu ? '新增超级管理员' : '修改超级管理员'" :visible.sync="showModalSu" :before-close="onCloseModalSu">
			<el-form @submit.native.prevent ref="su-form" :rules="rule_su" :model="suOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="10" :offset="1">
						<el-form-item label="姓名" prop="name">
							<el-input v-model="suOnSet.name" :placeholder="showAddModalSu?'新增超管不会覆盖已有账号姓名':''" />
						</el-form-item>
					</el-col>
					<el-col :span="10" :offset="2">
						<el-form-item label="手机号" prop="phoneno">
							<el-input v-model="suOnSet.phoneno" :disabled="showAddModalSu?false:true" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
						<el-form-item label="">
							<el-button type="danger" :style="{'visibility':(showAddModalSu||!suOnSet.id)?'hidden':'inherit'}" @click="onRemoveSu">删除</el-button>
							<el-button :style="{'visibility':(showAddModalSu||!suOnSet.id)?'hidden':'inherit'}" @click="resetPW">重置登陆密码</el-button>
						</el-form-item>
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModalSu">取消</el-button>
							<el-button type="primary" @click="onSaveSu">保存</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

		<el-dialog class="form-wrap" title="连锁排序" :visible.sync="show_sort_gyms">
			<div class="fc_red fs_small">注：红色代表连锁总部。</div>
			<div class="bc_golden_bright" style="border-radius:5px;display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
				<div id="sort-gyms" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;"></div>
			</div>
			<div style="margin-top:50px;display: flex;align-items: center;justify-content: flex-end;">
				<el-button plain @click="show_sort_gyms=false;">取消</el-button>
				<el-button type="primary" @click="doSortGroupGyms">保存排序</el-button>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	import {
		hex,
		hex_hmac
	} from "@/utils/hex.js"
	import {
		randStr
	} from "@/utils/formatData"
	import {
		mapState,
		mapActions
	} from 'vuex'
	import GymVue from "../auth/Gym.vue"
	import Sortable from 'sortablejs'
	const defaultGym = {
		name: '',
		group_sn: '',
		is_flagship: 0,
		tel_no: '',
		tel_name: ''
	}
	const defaultSu = {}
	export default {
		name: "root_gym",
		data() {
			return {
				cdit: "",
				loading: false,
				list: [],
				status: 1,
				total: 0,
				page_ix: 1,
				page_size: 10,

				gymOnSet: {
					...defaultGym
				},
				showAddModal: false,
				showSetModal: false,
				rule: {
					name: [{
						required: true,
						message: '请输入场馆名称',
						trigger: ['blur', 'change']
					}],
					group_sn: [{
						validator: (rule, value, callback) => {
							if (value.length >= 0 && value.length < 21) {
								callback();
							} else {
								callback(new Error('品牌名称20字以内'));
							}
						},
						trigger: ['blur', 'change']
					}],
					tel_no: [{
						required: true,
						message: '请输入场馆联系电话',
						trigger: ['blur', 'change']
					}, {
						validator: (rule, value, callback) => {
							if (/^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确的手机号'));
							}
						},
						trigger: ['blur', 'change']
					}],
					tel_name: [{
						required: true,
						message: '请输入场馆联系人',
						trigger: ['blur', 'change']
					}]
				},

				show_sort_gyms: false,
				sort_gyms_id: [],

				showAddModalSu: false,
				showSetModalSu: false,
				gym_id_su: 0,
				suOnSet: {
					...defaultSu
				},
				rule_su: {
					name: [{
						required: true,
						message: '请输入超管姓名',
						trigger: ['blur', 'change']
					}],
					phoneno: [{
						required: true,
						message: '请输入超管手机号',
						trigger: ['blur', 'change']
					}, {
						validator: (rule, value, callback) => {
							if (/^1[3456789]\d{9}$/.test(value)) {
								callback();
							} else {
								callback(new Error('请输入正确的手机号'));
							}
						},
						trigger: ['blur', 'change']
					}],
				}
			}
		},
		computed: {
			...mapState(["user"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			},
			showModalSu() {
				return this.showAddModalSu || this.showSetModalSu;
			}
		},
		mounted() {
			this.getList()

			document.body.ondrop = function(event) {
				event.preventDefault()
				event.stopPropagation()
			}
		},
		methods: {
			getList() {
				this.loading = true;
				this.$post("/admin/Root/queryGym", {
						user_id: this.user.id,
						cdit: this.cdit,
						status: this.status,
						page_ix: this.page_ix,
						page_size: this.page_size,
					})
					.then((res) => {
						if (res.rst == 0) {
							res = res.data
							this.list = res.data
							this.total = res.total
						}
					})
					.finally(() => {
						this.loading = false;
					})
			},
			onSearchClick() {
				this.page_ix = 1
				this.getList()
			},
			onPageChange(page) {
				this.page_ix = page
				this.getList()
			},
			goSet(gym) {
				this.gymOnSet = {
					...gym
				}
				this.showSetModal = true
			},
			onSave() {
				this.$refs['gym-form'].validate((valid) => {
					if (valid) {
						this.$confirm("该操作将保存场馆内容，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							let _data = {};
							if (this.showModal) {
								_data.name = this.gymOnSet.name || ""
								_data.tel_no = this.gymOnSet.tel_no || ""
								_data.tel_name = this.gymOnSet.tel_name || ""
							}
							if (this.showSetModal) {
								_data.gym_id = this.gymOnSet.id
							}
							if (this.showAddModal && 1 == this.gymOnSet.is_flagship) {
								if (!this.gymOnSet.group_name) {
									this.$message({
										message: '创建连锁总部必须输入品牌名',
										type: 'warning'
									})
									return
								}
								_data['is_flagship'] = this.gymOnSet.is_flagship
								_data['group_name'] = this.gymOnSet.group_name
								_data['group_new'] = 1
							}
							this.$post("/admin/Root/saveGym", _data).then((res) => {
								if (res.rst == 0) {
									this.getList();
									this.onCloseModal();
								}
							}).finally(() => {
								this.loading = false;
							})
						});
					}
				});
			},
			handleGymStatus(gym_id, status) {
				this.$confirm("该操作将" + (0 == status ? "删除" : (1 == status ? "恢复" : "")) + "所选场馆，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/Root/saveGym", {
						gym_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
			onCloseModal() {
				this.showAddModal = false
				this.showSetModal = false
				this.gymOnSet = {
					...defaultGym
				}
			},
			newGroup(gym_id) {
				if (0 > gym_id) {
					return
				}
				this.$prompt('请输入品牌总部名称，并创建总部。', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputValue: '',
					inputPattern: /^.{1,20}$/,
					inputErrorMessage: '总部品牌名称在1~20字以内',
					type: "info",
				}).then(({
					value
				}) => {
					this.$confirm('创建总部后为连锁提供支持，将不能删除，确认创建?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$post("/admin/Root/saveGym", {
							gym_id: gym_id,
							group_new: 1,
							group_name: value
						}).then((res) => {
							this.getList();
						})
					})
				})
			},
			inGroup(gym_id) {
				if (0 > gym_id) {
					return
				}
				this.$prompt('请输入品牌认证码，加入连锁得到总部支持。', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputValue: '',
					inputPattern: /^.{4,20}$/,
					inputErrorMessage: '认证码在4~20字以内',
					type: "info",
				}).then(({
					value
				}) => {
					this.$post("/admin/Root/saveGym", {
						gym_id: gym_id,
						group_in: 1,
						group_sn: value
					}).then((res) => {
						this.getList();
					})
				})
			},
			outGroup(gym_id) {
				if (0 >= gym_id) {
					return
				}
				this.$confirm("退出场馆所在连锁品牌将不可撤销，是否退出?", "提示", {
					confirmButtonText: "退出",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					let _data = {
						gym_id: gym_id,
						group_out: 1
					};
					this.$post("/admin/Root/saveGym", _data).then((res) => {
						if (res.rst == 0) {
							this.getList();
							//this.onCloseModal();
						}
					})
				})
			},
			changeGroupName(gym_id, group_name, is_flagship) {
				if (!gym_id || !group_name) {
					return
				}
				const prompt_txt = (is_flagship && 1 == is_flagship) ? '该操作将修改所有连锁场馆品牌名，且不可撤销，确认修改?' : '该操作仅修改本场馆品牌名，且不可撤销，确认修改?'
				this.$prompt(prompt_txt, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputValue: group_name,
					inputPattern: /^.{1,20}$/,
					inputErrorMessage: '品牌名在20字以内',
					type: "info",
				}).then(({
					value
				}) => {
					let _data = {
						gym_id: gym_id,
						group_name: value
					}
					if (is_flagship && 1 == is_flagship) {
						_data['isset_flags'] = 1
					}
					this.$post("/admin/Root/saveGym", _data).then((res) => {
						if (res.rst == 0) {
							this.getList();
						}
					})
				})
			},
			goOutFlagship(gym_id) {
				if (0 >= gym_id) {
					return
				}
				this.$confirm("警告：关闭总部将废弃所有对连锁场馆对支持，确认废弃?", "提示", {
					confirmButtonText: "确认",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$confirm("再次警告：关闭总部将废弃所有对连锁场馆对支持，确认废弃?", "提示", {
						confirmButtonText: "确认",
						cancelButtonText: "取消",
						type: "info",
					}).then(() => {
						let _data = {
							gym_id: gym_id,
							group_out: 1
						};
						this.$post("/admin/Root/saveGym", _data).then((res) => {
							if (res.rst == 0) {
								this.getList();
								this.onCloseModal();
							}
						})
					})
				});
			},
			toSortGroupGyms(flagship_id = 0) {
				if (0 >= flagship_id) {
					return
				}
				this.show_sort_gyms = true
				this.sort_gyms_id = []
				const that = this
				this.$post("/admin/Root/getGroupGyms", {
					flagship_id: flagship_id
				}).then((res) => {
					if (res.rst == 0) {
						const sortGyms = document.getElementById('sort-gyms')
						sortGyms.innerHTML = ''
						res.data.forEach(item => {
							that.sort_gyms_id.push(item.id)
							const div = document.createElement('div')
							div.textContent = item.name
							div.setAttribute('data_id', item.id)
							div.setAttribute('class', 1 == item.is_flagship ? 'bc_red' : 'bc_golden_bright')
							div.setAttribute('style', 'margin: 10px;padding: 6px 10px;border-radius: 2px;cursor: pointer;')
							const sub = document.createElement('div')
							sub.textContent = item.group_name
							sub.setAttribute('class', 1 == item.is_flagship ? 'fs_tiny fc_light' : 'fs_tiny fc_gray')
							div.appendChild(sub)
							sortGyms.appendChild(div)
						});
						new Sortable(sortGyms, {
							animation: 250
						});
					}
				})
			},
			doSortGroupGyms(flagship_id = 0) {
				this.$confirm("该操作将更新连锁场馆顺序，且不可撤销，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					const sortGyms = document.getElementById('sort-gyms')
					let id_sort_pairs = []
					Array.from(sortGyms.children).forEach((item, ix) => {
						id_sort_pairs.push({
							id: item.getAttribute('data_id'),
							sort: 1 + ix
						})
					})
					this.$post("/admin/Root/resortGroupGyms", {
						id_sort_pairs: JSON.stringify(id_sort_pairs)
					}).then(res => {
						if (res.rst == 0) {
							this.$message({
								message: res.msg,
								type: 0 == res.rst ? 'success' : 'error'
							})
							this.getList()
						}
					}).finally((res) => {
						this.show_sort_gyms = false
					})
				})

			},
			goSetSu(su) {
				this.suOnSet = {
					...su
				}
				this.showSetModalSu = true
			},
			onSaveSu() {
				const that = this
				this.$refs['su-form'].validate((valid) => {
					if (valid) {
						that.$confirm("该操作将保存修改超管内容，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							let _data = {};
							if (that.showModalSu) {
								_data.name = that.suOnSet.name || ""
								_data.phoneno = that.suOnSet.phoneno || ""
							}
							if (that.showAddModalSu) {
								_data.gym_id = that.gym_id_su
							} else if (that.showSetModalSu) {
								_data.ags_id = that.suOnSet.id
							} else;
							that.$post("/admin/Root/saveGymSuper", _data).finally(() => {
								that.getList()
								that.onCloseModalSu();
							});
						});
					}
				});
			},
			onRemoveSu() {
				this.$confirm("该操作将删除所选超管，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/Root/saveGymSuper", {
						ags_id: this.suOnSet.id,
						status: 0
					}).finally(() => {
						this.getList()
						this.onCloseModalSu();
					});
				});
			},
			onCloseModalSu() {
				this.showAddModalSu = false
				this.showSetModalSu = false
				this.gym_id_su = 0
				this.suOnSet = {
					...defaultSu
				}
			},

			resetPW() {
				const pw = randStr(8)
				this.$prompt("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					inputValue: pw,
					inputPattern: /^([0-9A-Za-z_]{6,20})$/,
					inputErrorMessage: '密码由“6~20位 数字、字母、_”组成',
					type: "info",
				}).then(({
					value
				}) => {
					this.$post("/admin/Root/saveGymSuper", {
						ags_id: this.suOnSet.id,
						password: hex_hmac(String(this.suOnSet.phoneno), hex(value))
					}).finally(() => {
						this.onCloseModalSu();
					});
				})
			}
		}
	};
</script>
<style lang="less">
	.gym {
		.toolbar {
			.operation {
				.search-ipt {
					width: 350px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}
		}

		#btn-adminer-new {
			background: #ea7ccc;
			border-color: #ea7ccc;
			color: white;
		}

		#btn-group-new {
			background: #73c0de;
			border-color: #73c0de;
			color: white;
		}
	}
</style>