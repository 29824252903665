import Vue from "vue";
import Vuex from "vuex";
import util from "./util";
Vue.use(Vuex);
const state = {
	ossParams: {
		accessKeyId: 'LTAI5tAZbKPJrQ1s6PGCbbDp',
		accessKeySecret: '3Ut38euZ05iY66lQXsLgztfNjCrNna',
		bucket: 'dalisfit-gym',
		region: '华东1（杭州）',
		cname: true,
		endpoint: 'dalisfit-gym.oss-cn-hangzhou.aliyuncs.com'
	},
	callbackUrl: "",
	user: null,
	nav: [{
		title: "全局设置",
		icon: "el-icon-s-tools",
		rules: ['globalset_notice'],
		children: [{
			title: "消息公告",
			route: "/globalset_notice",
			rule: 'globalset_notice'
		}]
	}, {
		title: "手机用户",
		icon: "el-icon-user-solid",
		rules: ['user_vip', 'user_trainer', 'user_trainerachieve', 'user_adminer', 'user_change'],
		children: [{
			title: "会员列表",
			route: "/user_vip",
			rule: 'user_vip',
		}, {
			title: "教练列表",
			route: "/user_trainer",
			rule: 'user_trainer',
		},  {
			title: "教练业绩",
			route: "/user_trainerachieve",
			rule: 'user_trainerachieve',
		}, {
			title: "员工列表",
			route: "/user_adminer",
			rule: 'user_adminer',
		}, {
			title: "账号变更",
			route: "/user_change",
			rule: 'user_change',
		}]
	},  {
		title: "课前测评",
		icon: "el-icon-s-management",
		rules: ['survey_fpa', 'survey_faq', 'survey_bm','survey_hs', 'survey_pft', 'survey_pfts','survey_pftd', 'survey_fms', 'survey_more'],
		children: [{
			title: "性格色彩",
			route: "/survey_fpa",
			rule: 'survey_fpa',
		}, {
			title: "问卷调查",
			route: "/survey_faq",
			rule: 'survey_faq',
		}, {
			title: "身体测评",
			route: "/survey_bm",
			rule: 'survey_bm',
		},{
			title: "健康筛查",
			route: "/survey_hs",
			rule: 'survey_hs',
		}, {
			title: "体适能测评",
			route: "/survey_pft",
			rule: 'survey_pft',
		}, {
			title: "静态测评",
			route: "/survey_pfts",
			rule: 'survey_pfts',
		},{
			title: "动态测评",
			route: "/survey_pftd",
			rule: 'survey_pftd',
		}, {
			title: "FMS测评",
			route: "/survey_fms",
			rule: 'survey_fms',
		}, {
			title: "自定义测评",
			route: "/survey_more",
			rule: 'survey_more',
		}]
	}, {
		title: "课程与计划",
		icon: "el-icon-s-platform",
		rules: ['course_catecourse', 'course_overview', 'course_planpage', 'course_plan', 'course_card'],
		children: [{
			title: "分类课程",
			route: "/course_catecourse",
			rule: 'course_catecourse',
		}, {
			title: "课程总览",
			route: "/course_overview",
			rule: 'course_overview',
		}, {
			title: "规划模板",
			route: "/course_planpage",
			rule: 'course_planpage',
		}, {
			title: "训练规划",
			route: "/course_plan",
			rule: 'course_plan',
		}, {
			title: "健身卡",
			route: "/course_card",
			rule: 'course_card',
		}]
	}, {
		title: "合同订单",
		icon: "el-icon-s-order",
		rules: ['order_contract'],
		children: [{
			title: "合同列表",
			route: "/order_contract",
			rule: 'order_contract',
		}]
	}, {
		title: "日常训练",
		icon: "el-icon-s-claim",
		rules: ['train_acttag','train_act','train_effect','train_viphandup','train_leavemsg'],
		children: [{
			title: "动作标签",
			route: "/train_acttag",
			rule: 'train_acttag',
		},{
			title: "动作库",
			route: "/train_act",
			rule: 'train_act',
		},{
			title: "训练效果",
			route: "/train_effect",
			rule: 'train_effect',
		},{
			title: "会员上报",
			route: "/train_viphandup",
			rule: 'train_viphandup',
		},{
			title: "会员留言",
			route: "/train_leavemsg",
			rule: 'train_leavemsg',
		}]
	}, {
		title: "审计日志",
		icon: "el-icon-tickets",
		rules: ['audit_logauth'],
		children: [{
			title: "PC后台日志",
			route: "/audit_logauth",
			rule: 'audit_logauth',
		}]
	}, {
		title: "场馆权限",
		icon: "el-icon-lock",
		rules: ['auth_gym', 'auth_rule'],
		children: [{
			title: "场馆管理",
			route: "/auth_gym",
			rule: 'auth_gym'
		}, {
			title: "权限列表",
			route: "/auth_rule",
			rule: 'auth_rule'
		}]
	}, {
		title: "登记管理",
		icon: "el-icon-notebook-2",
		rules: ['root_gym'],
		children: [{
			title: "场馆登记",
			route: "/root_gym",
			rule: 'root_gym'
		}]
	}],
	routesMap: [],
	rulesMap: {},
};
const user = util.getStore("user");
const callbackUrl = util.getStore("callbackUrl");
const routesMap = util.getStore("routesMap");
const rulesMap = util.getStore("rulesMap");
if (user) {
	state.user = user;
	state.callbackUrl = callbackUrl;
	state.routesMap = routesMap;
	state.rulesMap = rulesMap;
}
export default new Vuex.Store({
	state,
	mutations: {
		setCallbackUrl(state, url) {
			util.setStore("callbackUrl", url);
			state.callbackUrl = url;
		},
		updateRoutes(state, data) {
			util.setStore("routesMap", data);
			state.routesMap = data;
		},
		updateAddrMap(state, data) {
			util.setStore("addrMap", data);
			state.addrMap = data;
			let map = {};
			let map2 = {};
			data.forEach(p => {
				map[p.id] = {
					...p,
					children: {}
				};
				p.children.forEach(c => {
					map[p.id].children[c.id] = {
						...c,
						children: {}
					};
					map[c.id] = c;
					c.children.forEach(a => {
						map[p.id].children[c.id].children[a.id] = a;
						map[a.id] = a;
					});
				});
			});
			data.forEach(p => {
				map2[p.id] = {
					...p
				};
				p.children.forEach(c => {
					map2[p.id][c.id] = {
						...c
					};
					c.children.forEach(a => {
						map2[p.id][c.id][a.id] = a;
					});
				});
			});
			util.setStore("addrMap2", map);
			util.setStore("cityMap", map2);
			state.addrMap2 = map;
			state.cityMap = map2;
		},
		updateUser(state, data) {
			util.setStore("user", data);
			state.user = data;
		},
		updateRules(state, data) {
			let rulesMap = {}
			data.forEach(r => {
				rulesMap[r.id] = r;
			})
			util.setStore("rulesMap", rulesMap);
			state.rulesMap = rulesMap;
		},
		userLogout(state) {
			state.user = null
			state.callbackUrl = ""
			state.routesMap = []
			state.rulesMap = {}
			util.clearStore();
		}
	},
	actions: {
		setCallbackUrl({
			commit
		}, url) {
			commit("setCallbackUrl", url);
		},
		updateRoutes({
			commit
		}, data) {
			commit("updateRoutes", data);
		},
		updateAddrMap({
			commit
		}, data) {
			commit("updateAddrMap", data);
		},
		updateUser({
			commit
		}, data) {
			commit("updateUser", data);
		},
		updateRules({
			commit
		}, data) {
			commit("updateRules", data);
		},
		userLogout({
			commit
		}) {
			commit("userLogout");
		}
	},
});